// ConversationsPage.js
import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import ConversationList from '../../components/ConversationList/ConversationList';
import { Helmet } from 'react-helmet';
const PageContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f0f2f5;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
`;

const ConversationsPage = () => {
  return (
    <PageContainer>
      <Helmet>
    <title>blub.ai - Gespräche</title>
    <meta name="description" content="Verwalten Sie alle Ihre Telefonate und sehen Sie sich die Details im blub.ai-Dashboard an." />
    <meta name="robots" content="noindex, nofollow" />
    <link rel="canonical" href="https://www.blub.ai/conversations" />
</Helmet>

      <Sidebar />
      <ContentContainer>
        <ConversationList />
      </ContentContainer>
    </PageContainer>
  );
};

export default ConversationsPage;
