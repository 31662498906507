import React from 'react';
import { TableRow, TableCell, Typography } from '@mui/material';
import ActionButtons from './ActionButtons';
import PhoneNumberCell from './PhoneNumberCell';
import AttributeCell from './AttributeCell';
import SummaryCell from './SummaryCell';
import MemoizedAudioPlayer from '../CustomAudioPlayer';
import { formatDateString } from './utils';

const ConversationRow = ({
  conversation,
  attributes,
  handleOpenDialog,
  handleCopyToClipboard,
  handleSmsIconClick,
}) => (
  <TableRow
    key={conversation.conversation_id}
    hover
    sx={{
      position: 'relative',
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'rgb(249 250 251)',
        transform: 'scale(1.002)',
        transition: 'all 0.2s ease-in-out',
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
      },
      borderRadius: '0.75rem',
      transition: 'all 0.2s ease-in-out',
      '& td:first-of-type': {
        borderTopLeftRadius: '0.75rem',
        borderBottomLeftRadius: '0.75rem',
      },
      '& td:last-of-type': {
        borderTopRightRadius: '0.75rem',
        borderBottomRightRadius: '0.75rem',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: '5%',
        width: '90%',
        height: '1px',
        backgroundColor: 'rgb(243 244 246)',
      },
      my: 1,
    }}
  >
    <TableCell 
      sx={{ 
        textAlign: 'center', 
        padding: '16px 12px',
        backgroundColor: 'transparent',
      }}
    >
      <ActionButtons conversation={conversation} handleOpenDialog={handleOpenDialog} />
    </TableCell>

    <PhoneNumberCell
      phoneNumber={conversation.dynamic_attributes.phone_number}
      handleCopyToClipboard={handleCopyToClipboard}
    />

    {attributes.map((attribute) => (
      <AttributeCell
        key={attribute.attribute_name}
        attribute={attribute}
        dynamicAttributes={conversation.dynamic_attributes}
        originalAttributes={conversation.original_attributes}
      />
    ))}

    <TableCell 
      sx={{ 
        textAlign: 'center', 
        padding: '16px 12px',
        '& .MuiTypography-root': {
          color: 'rgb(107 114 128)',
          fontSize: '0.875rem',
          fontWeight: '500',
        }
      }}
    >
      <Typography>
        {formatDateString(conversation.created_at)}
      </Typography>
    </TableCell>

    <SummaryCell 
      conversation={conversation} 
      handleSmsIconClick={handleSmsIconClick} 
    />

    <TableCell 
      sx={{ 
        textAlign: 'center', 
        padding: '16px 12px',
        backgroundColor: 'transparent',
      }}
    >
      {conversation.has_audio ? (
        <div className="flex justify-center">
          <MemoizedAudioPlayer conversationId={conversation.conversation_id} />
        </div>
      ) : (
        <Typography 
          variant="caption" 
          sx={{
            color: 'rgb(107 114 128)',
            fontSize: '0.875rem',
          }}
        >
          No audio available
        </Typography>
      )}
    </TableCell>

    <TableCell 
      sx={{ 
        textAlign: 'center', 
        padding: '16px 12px',
        backgroundColor: 'transparent',
        '& .MuiTypography-root': {
          color: 'rgb(107 114 128)',
          fontSize: '0.875rem',
          fontWeight: '500',
        }
      }}
    >
      <Typography>
        {conversation.marked_by_user_name}
      </Typography>
    </TableCell>
   
  </TableRow>
);

export default ConversationRow;
