import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Button, 
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axiosInstance from '../axiosConfig';

const BenutzerVerwaltung = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState('');
  const [customerID, setCustomerID] = useState('');
  const [customers, setCustomers] = useState([]);
  const [roles] = useState(['Global Admin', 'Customer Admin', 'Customer User']);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [newUser, setNewUser] = useState({
    email: '',
    password: '',
    displayname: '',
    role: '',
    customer_id: '',
  });

  useEffect(() => {
    fetchUsers();
    fetchCustomers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/admin/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    const user = users.find(u => u.supabase_user_id === userId);
    setSelectedUser(userId);
    setDisplayName(user.displayname);
    setRole(user.role);
    setCustomerID(user.customer_id);
  };

  const handleSave = async () => {
    try {
      const selectedCustomer = customers.find(customer => customer.id === customerID);
      const customerName = selectedCustomer ? selectedCustomer.name : '';
  
      await axiosInstance.put(`/admin/users/${selectedUser}`, {
        displayname: displayName,
        role,
        customer_name: customerName
      });
      fetchUsers();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  const handleDeleteUser = async () => {
    if (!selectedUser) return;
    
    try {
      await axiosInstance.delete(`/admin/users/${selectedUser}`);
      setSelectedUser('');
      setDisplayName('');
      setRole('');
      setCustomerID('');
      fetchUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleCreateUser = async () => {
    try {
      await axiosInstance.post('/admin/users', newUser);
      setCreateDialogOpen(false);
      setNewUser({
        email: '',
        password: '',
        displayname: '',
        role: '',
        customer_id: '',
      });
      fetchUsers();
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography
          variant="h5"
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '600',
          }}
        >
          Benutzerverwaltung
        </Typography>
        
        <Button 
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => setCreateDialogOpen(true)}
          sx={{
            backgroundColor: 'rgb(59 130 246)',
            textTransform: 'none',
            fontWeight: '500',
            borderRadius: '0.5rem',
            '&:hover': {
              backgroundColor: 'rgb(37 99 235)',
            },
          }}
        >
          Neuer Benutzer
        </Button>
      </Box>

      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <FormControl fullWidth sx={{ ...inputStyles, mb: 4 }}>
          <InputLabel>Benutzer</InputLabel>
          <Select value={selectedUser} onChange={handleUserChange} label="Benutzer">
            {users.map((user) => (
              <MenuItem key={user.supabase_user_id} value={user.supabase_user_id}>
                {user.displayname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedUser && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <TextField
              fullWidth
              label="Display Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              sx={inputStyles}
            />
            
            <FormControl fullWidth sx={inputStyles}>
              <InputLabel>Rolle</InputLabel>
              <Select value={role} onChange={(e) => setRole(e.target.value)} label="Rolle">
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth sx={inputStyles}>
              <InputLabel>Kunde</InputLabel>
              <Select value={customerID} onChange={(e) => setCustomerID(e.target.value)} label="Kunde">
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
              <Button 
                variant="contained"
                onClick={handleSave}
                sx={{
                  backgroundColor: 'rgb(59 130 246)',
                  textTransform: 'none',
                  fontWeight: '500',
                  borderRadius: '0.5rem',
                  '&:hover': {
                    backgroundColor: 'rgb(37 99 235)',
                  },
                }}
              >
                Speichern
              </Button>
              <Button 
                variant="contained"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleDeleteUser}
                sx={{
                  textTransform: 'none',
                  fontWeight: '500',
                  borderRadius: '0.5rem',
                  backgroundColor: 'rgb(239 68 68)',
                  '&:hover': {
                    backgroundColor: 'rgb(220 38 38)',
                  },
                }}
              >
                Löschen
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      <Dialog 
        open={createDialogOpen} 
        onClose={() => setCreateDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '1rem',
            boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          },
        }}
      >
        <DialogTitle
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '600',
            borderBottom: '1px solid rgb(229 231 235)',
            px: 3,
            py: 2,
          }}
        >
          Neuen Benutzer erstellen
        </DialogTitle>
        
        <DialogContent sx={{ p: 3 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mt: 1 }}>
            <TextField
              fullWidth
              label="E-Mail"
              value={newUser.email}
              onChange={(e) => setNewUser({...newUser, email: e.target.value})}
              sx={inputStyles}
            />
            
            <TextField
              fullWidth
              label="Passwort"
              type="password"
              value={newUser.password}
              onChange={(e) => setNewUser({...newUser, password: e.target.value})}
              sx={inputStyles}
            />
            
            <TextField
              fullWidth
              label="Display Name"
              value={newUser.displayname}
              onChange={(e) => setNewUser({...newUser, displayname: e.target.value})}
              sx={inputStyles}
            />
            
            <FormControl fullWidth sx={inputStyles}>
              <InputLabel>Rolle</InputLabel>
              <Select
                value={newUser.role}
                onChange={(e) => setNewUser({...newUser, role: e.target.value})}
                label="Rolle"
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>
                    {role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <FormControl fullWidth sx={inputStyles}>
              <InputLabel>Kunde</InputLabel>
              <Select
                value={newUser.customer_id}
                onChange={(e) => setNewUser({...newUser, customer_id: e.target.value})}
                label="Kunde"
              >
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        
        <DialogActions sx={{ p: 3, borderTop: '1px solid rgb(229 231 235)' }}>
          <Button 
            onClick={() => setCreateDialogOpen(false)}
            sx={{
              color: 'rgb(107 114 128)',
              textTransform: 'none',
              fontWeight: '500',
              '&:hover': {
                backgroundColor: 'rgb(243 244 246)',
              },
            }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleCreateUser}
            variant="contained"
            sx={{
              backgroundColor: 'rgb(59 130 246)',
              textTransform: 'none',
              fontWeight: '500',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(37 99 235)',
              },
            }}
          >
            Erstellen
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BenutzerVerwaltung;
