import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactAudioPlayer from 'react-h5-audio-player';
import { Box, CircularProgress } from '@mui/material';
import axiosInstance from '../axiosConfig';
import 'react-h5-audio-player/lib/styles.css';
import './CustomAudioPlayer.css';

const CustomAudioPlayer = ({ conversationId }) => {
  const [audioSrc, setAudioSrc] = useState('');
  const [isInView, setIsInView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const playerRef = useRef(null);
  const observerRef = useRef(null);
  const containerRef = useRef(null);

  const fetchAudioSrc = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.get(`/audio/${conversationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      setAudioSrc(url);
    } catch (error) {
      console.error('Error fetching audio file', error);
      setError('Fehler beim Laden');
    } finally {
      setIsLoading(false);
    }
  }, [conversationId]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.audio.current.pause();
    }
  }, [audioSrc]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isInView && !audioSrc) {
      fetchAudioSrc();
    }
  }, [isInView, audioSrc, fetchAudioSrc]);

  return (
    <Box 
      ref={containerRef}
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: '50px',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        backgroundColor: 'white',
        '& .rhap_container': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          padding: '8px',
        },
        '& .rhap_main': {
          flexDirection: 'row',
          gap: '8px',
        },
        '& .rhap_progress-section': {
          flex: 1,
          '& .rhap_progress-bar': {
            height: '4px',
            '& .rhap_progress-indicator': {
              width: '12px',
              height: '12px',
              marginTop: '-4px',
              backgroundColor: 'rgb(59 130 246)',
            },
            '& .rhap_progress-filled': {
              backgroundColor: 'rgb(59 130 246)',
            },
          },
        },
        '& .rhap_controls-section': {
          flex: 'none',
          justifyContent: 'flex-start',
        },
        '& .rhap_additional-controls': {
          display: 'none',
        },
        '& .rhap_volume-controls': {
          display: 'none',
        },
        '& .rhap_play-pause-button': {
          width: '32px',
          height: '32px',
          minWidth: '32px',
          padding: 0,
          borderRadius: '50%',
          backgroundColor: 'rgb(239 246 255)',
          color: 'rgb(59 130 246)',
          transition: 'all 0.2s',
          '&:hover': {
            backgroundColor: 'rgb(219 234 254)',
          },
          '& svg': {
            width: '16px',
            height: '16px',
          },
        },
        '& .rhap_time': {
          color: 'rgb(107 114 128)',
          fontSize: '0.75rem',
        },
      }}
    >
      {isLoading ? (
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
          }}
        >
          <CircularProgress 
            size={24}
            sx={{ 
              color: 'rgb(59 130 246)',
            }}
          />
        </Box>
      ) : error ? (
        <Box 
          sx={{ 
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50px',
            color: 'rgb(239 68 68)',
            fontSize: '0.875rem',
          }}
        >
          {error}
        </Box>
      ) : (
        <ReactAudioPlayer
          ref={playerRef}
          src={audioSrc}
          autoPlay={false}
          controls
          layout="horizontal-reverse"
          customProgressBarSection={[
            "PROGRESS_BAR",
            "CURRENT_TIME",
            "DURATION",
          ]}
          customControlsSection={[
            "MAIN_CONTROLS",
          ]}
          style={{
            borderRadius: '0.5rem',
            backgroundColor: 'transparent',
          }}
        />
      )}
    </Box>
  );
};

export default CustomAudioPlayer;
