import React from 'react';
import styled from 'styled-components';
import Sidebar from '../../components/Sidebar';
import Statistics from '../../components/Statistics';
import { Helmet } from 'react-helmet';

const PageContainer = styled.div`
  display: flex;
  height: 100vh;
  background-color: #fff;
`;

const ContentContainer = styled.div`
  flex: 1;
  padding: 40px;
  overflow-y: auto;
  background-color: #fff;
`;

const Header = styled.h1`
  text-align: center;
  font-size: 3rem;
  margin-bottom: 30px;
  color: #000;
  font-weight: bold;
`;

const StatisticsPage = () => {
  return (
    <PageContainer>
      <Helmet>
        <title>blub.ai - Statistiken</title>
        <meta name="description" content="Analysieren Sie Ihre Anrufstatistiken und Performance-Daten im blub.ai-Dashboard." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.blub.ai/statistics" />
      </Helmet>

      <Sidebar />
      <ContentContainer>
        <Header>Statistics</Header>
        <Statistics />
      </ContentContainer>
    </PageContainer>
  );
};

export default StatisticsPage;
