import React from 'react';
import { Box, Typography, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Comment } from '@mui/icons-material';

export const convertToLocalTime = (timestamp) => {
  const date = new Date(timestamp);
  const offset = date.getTimezoneOffset(); // Minutenverschiebung von UTC
  const localDate = new Date(date.getTime() - (offset * 60 * 1000)); // Umrechnung auf lokale Zeit
  return localDate.toLocaleString(); // Ausgabe im lokalen Format
};

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const padToTwoDigits = (num) => String(num).padStart(2, '0');

  return `${padToTwoDigits(date.getDate())}.${padToTwoDigits(
    date.getMonth() + 1
  )}.${date.getFullYear()} ${padToTwoDigits(date.getHours())}:${padToTwoDigits(
    date.getMinutes()
  )}:${padToTwoDigits(date.getSeconds())}`;
};

export const renderDynamicTheme = (theme) => {
  try {
    const themeObject = JSON.parse(theme);
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {Object.keys(themeObject).map((key) => (
          <Typography key={key} variant="body2">
            <strong>{key}:</strong> {themeObject[key]}
          </Typography>
        ))}
      </Box>
    );
  } catch (e) {
    console.error('Invalid JSON in theme field:', theme);
    return (
      <Typography variant="body2">
        Ungültige Daten im Theme-Feld
      </Typography>
    );
  }
};

export const renderResultItem = (result) => {
  const themeContent = renderDynamicTheme(result.theme);

  return (
    <ListItem
      button
      onClick={() =>
        (window.location.href = `/conversation/${result.conversation_id}`)
      }
      key={result.conversation_id}
      sx={{ flexDirection: 'column', alignItems: 'flex-start', padding: 2 }}
    >
      <ListItemIcon>
        <Comment color="action" />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {`Anliegen: ${result?.concise_summary}`}
          </Typography>
        }
        secondary={
          <>
            {themeContent}
            <Typography variant="body2">
            <strong>Zusammenfassung:</strong> {result?.summary}
            </Typography>
            <Typography variant="body2">
              <strong>Telefon:</strong> {result?.phone_number}
            </Typography>
            <Typography variant="body2">
              <strong>Datum:</strong> {convertToLocalTime(result?.created_at)}
            </Typography>
            <Typography variant="body2">
              <strong>Abgehakt von:</strong> {result?.marked_by_user_name}
            </Typography>
            <Tooltip title={`ID: ${result?.conversation_id}`}>
              <Typography
                variant="caption"
                sx={{ mt: 1, display: 'block', color: 'gray' }}
              >
                Gesprächs-ID anzeigen
              </Typography>
            </Tooltip>
          </>
        }
      />
    </ListItem>
  );
};
