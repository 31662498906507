import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosConfig';
import { io } from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  Container,
} from '@mui/material';

const getStatusColor = (status) => {
  switch (status) {
    case 'Available':
      return 'rgb(34 197 94)'; // green-500
    case 'On Call':
      return 'rgb(234 179 8)'; // yellow-500
    case 'Offline':
      return 'rgb(239 68 68)'; // red-500
    default:
      return 'rgb(156 163 175)'; // gray-400
  }
};

const getStatusMessage = (status) => {
  switch (status) {
    case 'Available':
      return 'Verfügbar';
    case 'On Call':
      return 'Im Anruf';
    case 'Offline':
      return 'Offline';
    default:
      return 'Unknown';
  }
};

const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await axiosInstance.get('/agents');
        setAgents(response.data);
      } catch (error) {
        console.error('Error fetching agents', error);
        setSnackbar({ open: true, message: 'Error fetching agents', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();

    const socket = io('https://blub.ai', {
      path: '/socket.io',
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('update_agent_status', (updatedAgent) => {
      setAgents((prevAgents) => {
        return prevAgents.map((agent) =>
          agent.ConfigID === parseInt(updatedAgent.ConfigID, 10)
            ? { ...agent, Status: updatedAgent.Status, ConversationID: updatedAgent.ConversationID }
            : agent
        );
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleShowCall = async (conversationId, configId) => {
    setLoading(true);
    try {
      if (!conversationId) {
        const response = await axiosInstance.get(`/agents/${configId}/conversation`);
        if (response.data && response.data.CurrentConversation) {
          navigate(`/conversation/${response.data.CurrentConversation}`);
        } else {
          setSnackbar({ open: true, message: 'Current conversation not found', severity: 'error' });
        }
      } else {
        navigate(`/conversation/${conversationId}`);
      }
    } catch (error) {
      setSnackbar({ open: true, message: 'Error fetching conversation', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'rgb(249 250 251)',
      }}
    >
      <Helmet>
        <title>blub.ai - Agenten</title>
        <meta name="description" content="Verwalten Sie Ihre Agenten und deren Anrufzuweisungen im blub.ai-Dashboard." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.blub.ai/agents" />
      </Helmet>

      <Sidebar />
      
      <Box 
        sx={{ 
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '-10%',
            right: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.1) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-10%',
            left: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.05) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
            zIndex: 0,
          }}
        />

        <Container 
          maxWidth="xl" 
          sx={{ 
            py: 4,
            px: { xs: 2, sm: 4 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          {/* Header */}
          <Typography
            variant="h4"
            component="h1"
            sx={{
              textAlign: 'center',
              marginBottom: 4,
              fontWeight: '700',
              color: 'rgb(17 24 39)',
              position: 'relative',
            }}
          >
            Agenten
            <Box
              sx={{
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60px',
                height: '4px',
                background: 'rgb(59 130 246)',
                borderRadius: '2px',
              }}
            />
          </Typography>

          {/* Agents Grid */}
          <Grid container spacing={3}>
            {loading ? (
              // Loading skeletons
              Array.from(new Array(4)).map((_, index) => (
                <Grid item xs={12} md={6} lg={4} key={index}>
                  <Card
                    sx={{
                      height: '200px',
                      backgroundColor: 'rgba(255, 255, 255, 0.7)',
                      backdropFilter: 'blur(10px)',
                      animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
                      '@keyframes pulse': {
                        '0%, 100%': {
                          opacity: 1,
                        },
                        '50%': {
                          opacity: .5,
                        },
                      },
                    }}
                  />
                </Grid>
              ))
            ) : (
              // Actual agent cards
              agents.map((agent) => (
                <Grid item xs={12} md={6} lg={4} key={agent.ConfigID}>
                  <Card
                    sx={{
                      position: 'relative',
                      padding: 2,
                      backgroundColor: 'rgba(255, 255, 255, 0.9)',
                      backdropFilter: 'blur(10px)',
                      borderRadius: '1rem',
                      border: '1px solid rgba(229, 231, 235, 1)',
                      boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
                      transition: 'all 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
                      },
                    }}
                  >
                    {/* Status Indicator */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                        width: '12px',
                        height: '12px',
                        borderRadius: '50%',
                        backgroundColor: getStatusColor(agent.Status),
                        boxShadow: `0 0 0 3px ${getStatusColor(agent.Status)}33`,
                      }}
                    />

                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{
                          color: 'rgb(17 24 39)',
                          fontWeight: '600',
                          mb: 2,
                        }}
                      >
                        {agent.Username}
                      </Typography>
                      <Typography
                        sx={{
                          color: 'rgb(107 114 128)',
                          fontSize: '0.875rem',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        Status: {getStatusMessage(agent.Status)}
                      </Typography>
                    </CardContent>

                    <CardActions sx={{ padding: '16px' }}>
                      {agent.Status === 'On Call' && (
                        <Button
                          variant="contained"
                          onClick={() => handleShowCall(agent.ConversationID, agent.ConfigID)}
                          fullWidth
                          disabled={loading}
                          sx={{
                            backgroundColor: 'rgb(59 130 246)',
                            color: 'white',
                            textTransform: 'none',
                            fontWeight: '500',
                            padding: '8px 16px',
                            borderRadius: '0.5rem',
                            '&:hover': {
                              backgroundColor: 'rgb(37 99 235)',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgb(219 234 254)',
                              color: 'rgb(147 197 253)',
                            },
                          }}
                        >
                          {loading ? (
                            <CircularProgress 
                              size={24} 
                              sx={{ 
                                color: 'rgb(59 130 246)',
                              }} 
                            />
                          ) : (
                            'Zeige Anruf'
                          )}
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{
            borderRadius: '0.5rem',
            backgroundColor: 'white',
            color: snackbar.severity === 'error' ? 'rgb(239 68 68)' : 'rgb(34 197 94)',
            '& .MuiAlert-icon': {
              color: snackbar.severity === 'error' ? 'rgb(239 68 68)' : 'rgb(34 197 94)',
            },
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Agents;
