import React, { useState } from 'react';
import { useSpring, animated } from '@react-spring/web';
import { Link } from 'react-router-dom';
import { Check, Zap, Building2, BadgeCheck } from 'lucide-react';

const pricingPlans = [
  {
    icon: Zap,
    title: 'Basis',
    description: 'Ideal für kleine Unternehmen und Startups',
    price: {
      monthly: '0.19€',
      yearly: '0.19€'
    },
    unit: 'pro Konversation',
    popular: false,
    features: [
      'Automatische Anrufannahme',
      'Live-Transkription',
      'Intelligente Gesprächsführung',
      'Integration per SIP oder Weiterleitung',
    ]
  },
  {
    icon: BadgeCheck,
    title: 'Pro',
    description: 'Perfekt für wachsende Unternehmen',
    price: {
      monthly: '99€',
      yearly: '69€'
    },
    unit: 'pro Agent / Monat',
    popular: true,
    features: [
      'Alle Basis-Features',
      'Unbegrenzte Konversationen',
      'Mehrere Benutzer & Mandanten',
      'Priorisierter Support',
      'Echtzeit-Analysen',
      'Antworten per SMS'
    ]
  },
  {
    icon: Building2,
    title: 'Enterprise',
    description: 'Maßgeschneiderte Lösungen für Großunternehmen',
    price: {
      monthly: 'Individuell',
      yearly: 'Individuell'
    },
    unit: 'Kontaktieren Sie uns',
    popular: false,
    features: [
      'Alle Pro-Features',
      'Dedizierter Telefonsupport',
      'Individuelle Integration',
      'SLA-Garantie',
      'On-Premise Option',
      'Compliance-Pakete'
    ]
  }
];

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState('monthly');
  
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/50 py-24">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
        <div className="absolute -right-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Transparente Preisgestaltung
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Wählen Sie Ihren
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">perfekten Plan</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Flexible Preismodelle, die mit Ihrem Unternehmen wachsen
          </p>

          {/* Billing Toggle */}
          <div className="flex items-center justify-center mt-8">
            <div className="relative flex p-1 bg-blue-50 rounded-full">
              <button
                onClick={() => setBillingCycle('monthly')}
                className={`px-4 py-2 text-sm font-medium rounded-full transition-all ${
                  billingCycle === 'monthly' 
                    ? 'bg-white text-blue-600 shadow-sm' 
                    : 'text-gray-600 hover:text-blue-600'
                }`}
              >
                Monatlich
              </button>
             
            </div>
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:gap-8">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className={`relative group bg-white rounded-2xl p-8 transition-all duration-300 hover:scale-105 ${
                plan.popular 
                  ? 'border-2 border-blue-600 shadow-xl' 
                  : 'border border-gray-100 shadow-lg'
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2 px-4 py-1 bg-blue-600 text-white text-sm font-medium rounded-full">
                  Meistgewählt
                </div>
              )}

              {/* Plan Header */}
              <div className="flex items-center space-x-4">
                <div className="p-2 bg-blue-50 rounded-lg">
                  <plan.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900">{plan.title}</h3>
              </div>
              
              <p className="mt-4 text-gray-600">{plan.description}</p>

              {/* Pricing */}
              <div className="mt-6">
                <div className="flex items-baseline">
                  <span className="text-4xl font-bold text-gray-900">
                    {plan.price[billingCycle]}
                  </span>
                  <span className="ml-2 text-gray-600">
                    {plan.unit}
                  </span>
                </div>
              </div>

              {/* Features */}
              <ul className="mt-8 space-y-4">
                {plan.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 text-blue-600 mr-3 flex-shrink-0" />
                    {feature}
                  </li>
                ))}
              </ul>

              {/* CTA Button */}
              <Link 
                to="/demo" 
                className={`mt-8 block w-full px-6 py-3 text-center font-medium rounded-lg transition-all ${
                  plan.popular
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-blue-50 text-blue-600 hover:bg-blue-100'
                }`}
              >
                {plan.title === 'Enterprise' ? 'Kontaktieren Sie uns' : 'Jetzt starten'}
              </Link>
            </div>
          ))}
        </div>

        {/* Footer */}
        <div className="mt-16 text-center">
          <p className="text-sm text-gray-500 max-w-3xl mx-auto">
            Alle Preise zzgl. MwSt. Die Abrechnung erfolgt zu Beginn des Abrechnungszeitraums. 
            Bei Überschreitung des monatlichen Kontingents erfolgt eine nachträgliche Berechnung.
          </p>
          <Link 
            to="/compare" 
            className="inline-flex items-center mt-6 text-blue-600 hover:text-blue-700"
          >
            <span className="border-b border-blue-600">Alle Pakete im Detail vergleichen</span>
            <svg 
              className="w-4 h-4 ml-2 group-hover:translate-x-1 transition-transform" 
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Pricing;