import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, BarChart, Bar, PieChart, Pie, Cell, Legend } from 'recharts';
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { TrendingUp, Clock, Users, MessageSquare, RefreshCcw, Timer } from 'lucide-react';

const Statistics = () => {
  const [stats, setStats] = useState(null);
  const [peakCallTimes, setPeakCallTimes] = useState(null);
  const [callDurationByTime, setCallDurationByTime] = useState(null);
  const [repeatCalls, setRepeatCalls] = useState(null);
  const [costAnalysis, setCostAnalysis] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statsRes, peakRes, durationRes, repeatRes, costRes] = await Promise.all([
          axiosInstance.get('/statistics'),
          axiosInstance.get('/peak_call_times'),
          axiosInstance.get('/call_duration_by_time'),
          axiosInstance.get('/repeat_calls'),
          axiosInstance.get('/cost_analysis')
        ]);

        setStats(statsRes.data);
        setPeakCallTimes(peakRes.data.peak_call_times);
        setCallDurationByTime(durationRes.data.call_duration_by_time);
        setRepeatCalls(repeatRes.data.repeat_calls);
        setCostAnalysis(costRes.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten', error);
      }
    };

    fetchData();
  }, []);

  if (!stats || !peakCallTimes || !callDurationByTime || !repeatCalls || !costAnalysis) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress className="text-blue-600" />
      </div>
    );
  }

  const {
    total_words_spoken_by_ai,
    average_conversation_duration,
    total_conversations,
    average_response_time,
    common_categories,
    activity_over_time,
    phone_activity
  } = stats;

  const reversedActivityOverTime = [...activity_over_time].reverse();
  const colors = ['#3B82F6', '#60A5FA', '#93C5FD', '#BFDBFE', '#DBEAFE', '#EFF6FF'];

  const StatCard = ({ icon: Icon, title, value, subtitle }) => (
    <div className="bg-white rounded-xl border border-gray-100 p-6 transition-all duration-300 hover:shadow-lg hover:scale-[1.02] group">
      <div className="flex items-start justify-between">
        <div>
          <p className="text-sm font-medium text-gray-500">{title}</p>
          <p className="mt-2 text-3xl font-bold text-gray-900">{value}</p>
          {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
        </div>
        <div className="p-2 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
          <Icon className="w-6 h-6 text-blue-600" />
        </div>
      </div>
    </div>
  );

  const ChartCard = ({ title, children }) => (
    <div className="bg-white rounded-xl border border-gray-100 p-6 transition-all duration-300 hover:shadow-lg">
      <h3 className="text-lg font-semibold text-gray-900 mb-4">{title}</h3>
      {children}
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-white p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <StatCard
            icon={MessageSquare}
            title="Von der KI gesprochene Wörter"
            value={total_words_spoken_by_ai.toLocaleString()}
          />
          <StatCard
            icon={Clock}
            title="Durchschnittliche Gesprächsdauer"
            value={`${average_conversation_duration}s`}
          />
          <StatCard
            icon={TrendingUp}
            title="Gesamtanzahl der Gespräche"
            value={total_conversations.toLocaleString()}
          />
          <StatCard
            icon={Timer}
            title="Durchschnittliche Antwortzeit"
            value={`${average_response_time}s`}
          />
          <StatCard
            icon={Users}
            title="Einzigartige Anrufer"
            value={repeatCalls.filter(call => !call.is_repeat).length.toLocaleString()}
          />
          <StatCard
            icon={RefreshCcw}
            title="Wiederkehrende Anrufer"
            value={repeatCalls.filter(call => call.is_repeat).length.toLocaleString()}
          />
        </div>

        {/* Charts Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <ChartCard title="Konversationen pro Tag">
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={reversedActivityOverTime}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis dataKey="date" stroke="#6B7280" />
                <YAxis stroke="#6B7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem',
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="conversations_per_day"
                  name="Konversationen"
                  stroke="#3B82F6"
                  strokeWidth={2}
                  dot={{ fill: '#3B82F6' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartCard>

          <ChartCard title="Telefonaktivität nach Wochentag">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={phone_activity}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis dataKey="day_of_week" stroke="#6B7280" />
                <YAxis stroke="#6B7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem',
                  }}
                />
                <Legend />
                <Bar dataKey="morning" stackId="a" fill="#93C5FD" name="Morgen" />
                <Bar dataKey="midday" stackId="a" fill="#60A5FA" name="Mittag" />
                <Bar dataKey="evening" stackId="a" fill="#3B82F6" name="Abend" />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>

          <ChartCard title="Spitzenzeiten der Anrufe">
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={peakCallTimes}>
                <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                <XAxis dataKey="hour" stroke="#6B7280" />
                <YAxis stroke="#6B7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem',
                  }}
                />
                <Bar dataKey="call_count" fill="#3B82F6" name="Anrufe" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </ChartCard>

          <ChartCard title="Gesprächskategorien">
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={common_categories}
                  dataKey="category_count"
                  nameKey="category_name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {common_categories.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip
                  contentStyle={{
                    backgroundColor: 'white',
                    border: '1px solid #E5E7EB',
                    borderRadius: '0.5rem',
                  }}
                />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </ChartCard>
        </div>

        {/* Cost Analysis Table */}
        <div className="bg-white rounded-xl border border-gray-100 p-6 transition-all duration-300 hover:shadow-lg">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Kostenanalyse für diesen Monat</h3>
          <TableContainer component={Paper} elevation={0} className="border border-gray-100 rounded-xl">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="font-semibold">Beschreibung</TableCell>
                  <TableCell align="right" className="font-semibold">Wert</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Gesamtdauer der Anrufe</TableCell>
                  <TableCell align="right">{costAnalysis.total_call_duration_hours.toFixed(2)} Stunden</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kosten für einen menschlichen Mitarbeiter</TableCell>
                  <TableCell align="right">{costAnalysis.human_cost.toFixed(2)} €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Kosten für {costAnalysis.agent_config_count} Agenten</TableCell>
                  <TableCell align="right">
                    {((costAnalysis.virtual_assistant_monthly_cost / (4 * 168)).toFixed(2) * costAnalysis.total_call_duration_hours.toFixed(2)).toFixed(2)} €
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Monatliche Kosten für Agenten</TableCell>
                  <TableCell align="right">{costAnalysis.virtual_assistant_monthly_cost.toFixed(2)} €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="font-semibold">Ersparnis</TableCell>
                  <TableCell align="right" className="font-semibold text-green-600">
                    {(costAnalysis.human_cost - costAnalysis.virtual_assistant_monthly_cost).toFixed(2)} €
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
