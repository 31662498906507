import React from 'react';
import { Helmet } from 'react-helmet';
import { 
  Phone, 
  Bot, 
  FileText, 
  Link, 
  Users, 
  LineChart, 
  PhoneForwarded,
  List,
  MessageSquare,
  Headphones,
  Mic,
  UserCog,
  BadgeCheck,
  X,
  CheckCircle2,
  XCircle,
  HelpCircle,
  PhoneCall
} from 'lucide-react';

const features = [
  {
    category: 'Grundfunktionen',
    items: [
      { 
        icon: Phone,
        name: 'Automatische Anrufannahme',
        description: 'Professionelle Anrufentgegennahme rund um die Uhr',
        basic: true,
        pro: true,
        enterprise: true
      },
      { 
        icon: Bot,
        name: 'Intelligente Gesprächsführung',
        description: 'KI-gestützte natürliche Konversation',
        basic: true,
        pro: true,
        enterprise: true
      },
      { 
        icon: FileText,
        name: 'Live-Transkription',
        description: 'Sofortige Verschriftlichung aller Gespräche',
        basic: true,
        pro: true,
        enterprise: true
      },
      { 
        icon: Link,
        name: 'System-Integration',
        description: 'Anbindung an bestehende Telefonanlagen',
        basic: true,
        pro: true,
        enterprise: true
      },
      { 
        icon: PhoneCall,
        name: 'Anrufe übernehmen',
        description: 'Nahtlose Übernahme laufender Gespräche',
        basic: true,
        pro: true,
        enterprise: true
      }
    ]
  },
  {
    category: 'Erweiterte Funktionen',
    items: [
      { 
        icon: Users,
        name: 'Benutzer-Accounts',
        description: 'Anzahl der verfügbaren Benutzerkonten',
        basic: '5 Benutzer',
        pro: '25 Benutzer',
        enterprise: 'Unbegrenzt'
      },
      { 
        icon: LineChart,
        name: 'Echtzeit-Analysen',
        description: 'Detaillierte Auswertungen und Reports',
        basic: 'Basic',
        pro: 'Erweitert',
        enterprise: 'Premium'
      },
      { 
        icon: MessageSquare,
        name: 'SMS-Antworten',
        description: 'Automatische SMS-Benachrichtigungen',
        basic: false,
        pro: true,
        enterprise: true
      },
      { 
        icon: List,
        name: 'DTMF-Konfiguration',
        description: 'Anpassbare Tasteneingaben',
        basic: true,
        pro: true,
        enterprise: true
      }
    ]
  },
  {
    category: 'Support & Service',
    items: [
      { 
        icon: Headphones,
        name: 'Support-Level',
        description: 'Verfügbare Support-Optionen',
        basic: 'Email',
        pro: 'Priorisiert',
        enterprise: '24/7 Telefon'
      },
      { 
        icon: Mic,
        name: 'Eigene Stimme',
        description: 'Individuelle Sprachausgabe',
        basic: false,
        pro: false,
        enterprise: true
      },
      { 
        icon: UserCog,
        name: 'Account Manager',
        description: 'Persönliche Betreuung',
        basic: false,
        pro: false,
        enterprise: true
      }
    ]
  }
];

const plans = [
  {
    name: 'Basis',
    price: '0.19€',
    unit: 'pro Minute'
  },
  {
    name: 'Pro',
    price: '99€',
    unit: 'pro Monat',
    popular: true
  },
  {
    name: 'Enterprise',
    price: 'Individuell',
    unit: 'Kontaktieren Sie uns'
  }
];

const Comparison = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/50 py-24">
      <Helmet>
        <title>blub.ai - Funktionen vergleichen</title>
        <meta name="description" content="Vergleichen Sie die Funktionen des blub.ai-Telefonassistenten mit anderen Lösungen auf dem Markt." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/compare" />
      </Helmet>

      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
        <div className="absolute -right-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Detaillierter Vergleich
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Alle Funktionen im
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">Überblick</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Wählen Sie das passende Paket für Ihre individuellen Anforderungen
          </p>
        </div>

        {/* Plan Headers */}
        <div className="grid grid-cols-4 gap-4 mb-8 px-4">
          <div className="col-span-1"></div>
          {plans.map((plan, index) => (
            <div 
              key={index} 
              className={`col-span-1 p-4 rounded-xl ${
                plan.popular ? 'bg-blue-50' : 'bg-white'
              }`}
            >
              <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
              <div className="mt-2">
                <span className="text-2xl font-bold text-gray-900">{plan.price}</span>
                <span className="text-sm text-gray-600 ml-1">{plan.unit}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Feature Comparison */}
        <div className="space-y-8">
          {features.map((category, categoryIndex) => (
            <div key={categoryIndex} className="bg-white rounded-2xl shadow-lg overflow-hidden">
              <div className="px-6 py-4 bg-gray-50">
                <h3 className="text-lg font-semibold text-gray-900">{category.category}</h3>
              </div>
              <div className="divide-y divide-gray-100">
                {category.items.map((feature, featureIndex) => (
                  <div key={featureIndex} className="grid grid-cols-4 gap-4 px-6 py-4 items-center hover:bg-gray-50">
                    <div className="col-span-1 flex items-center space-x-3">
                      <div className="p-2 bg-blue-50 rounded-lg">
                        <feature.icon className="w-5 h-5 text-blue-600" />
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">{feature.name}</p>
                        <p className="text-sm text-gray-500">{feature.description}</p>
                      </div>
                    </div>
                    {[feature.basic, feature.pro, feature.enterprise].map((value, index) => (
                      <div key={index} className="col-span-1 text-center">
                        {typeof value === 'boolean' ? (
                          value ? (
                            <CheckCircle2 className="w-6 h-6 text-green-500 mx-auto" />
                          ) : (
                            <XCircle className="w-6 h-6 text-gray-300 mx-auto" />
                          )
                        ) : (
                          <span className="text-sm text-gray-700">{value}</span>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Comparison;