import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { CheckCircle2, XCircle, X, Building2, User, Mail, Phone, Briefcase, Users, Monitor, Clock, FileText, MessageSquare, Loader2 } from 'lucide-react';
import axiosInstance from '../../axiosConfig';

// Custom Alert Component (gleich wie in der Contact-Komponente)
const CustomAlert = ({ type, title, message, onClose }) => {
  const isError = type === 'error';
  
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`relative rounded-lg p-4 ${
        isError ? 'bg-red-50 text-red-900' : 'bg-green-50 text-green-900'
      }`}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {isError ? (
            <XCircle className={`h-5 w-5 ${isError ? 'text-red-400' : 'text-green-400'}`} />
          ) : (
            <CheckCircle2 className={`h-5 w-5 ${isError ? 'text-red-400' : 'text-green-400'}`} />
          )}
        </div>
        <div className="ml-3 w-full">
          <h3 className={`text-sm font-medium ${
            isError ? 'text-red-800' : 'text-green-800'
          }`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${
            isError ? 'text-red-700' : 'text-green-700'
          }`}>
            {message}
          </div>
        </div>
        <button
          onClick={onClose}
          className={`ml-auto flex-shrink-0 -mx-1.5 -my-1.5 p-1.5 rounded-lg inline-flex ${
            isError 
              ? 'text-red-500 hover:bg-red-100 focus:ring-red-400' 
              : 'text-green-500 hover:bg-green-100 focus:ring-green-400'
          } focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    </motion.div>
  );
};

// Form Steps Component
const FormStep = ({ step, formData, handleChange, disabled }) => {
  const inputClassName = "block w-full pl-10 rounded-lg border border-gray-200 py-3 text-sm outline-none placeholder:text-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed";
  
  const steps = {
    1: (
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Building2 className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            placeholder="Unternehmensname"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <User className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="contactPerson"
            value={formData.contactPerson}
            onChange={handleChange}
            placeholder="Ansprechpartner"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Mail className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email-Adresse"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
      </div>
    ),
    2: (
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Briefcase className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="industry"
            value={formData.industry}
            onChange={handleChange}
            placeholder="Branche"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Users className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="number"
            name="employees"
            value={formData.employees}
            onChange={handleChange}
            placeholder="Anzahl Mitarbeiter"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
      </div>
    ),
    3: (
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Monitor className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            name="currentSystem"
            value={formData.currentSystem}
            onChange={handleChange}
            placeholder="Aktuelles System"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Clock className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="number"
            name="monthlyHours"
            value={formData.monthlyHours}
            onChange={handleChange}
            placeholder="Monatliche Stunden"
            className={inputClassName}
            required
            disabled={disabled}
          />
        </div>
      </div>
    ),
    4: (
      <div className="space-y-4">
        <div className="relative">
          <div className="absolute top-3 left-3 pointer-events-none">
            <FileText className="h-5 w-5 text-gray-400" />
          </div>
          <textarea
            name="additionalRequirements"
            value={formData.additionalRequirements}
            onChange={handleChange}
            placeholder="Zusätzliche Anforderungen"
            rows="3"
            className={inputClassName}
            disabled={disabled}
          />
        </div>
        <div className="relative">
          <div className="absolute top-3 left-3 pointer-events-none">
            <MessageSquare className="h-5 w-5 text-gray-400" />
          </div>
          <textarea
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            placeholder="Kommentare"
            rows="3"
            className={inputClassName}
            disabled={disabled}
          />
        </div>
      </div>
    ),
  };

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.3 }}
    >
      {steps[step]}
    </motion.div>
  );
};

const DemoRequestForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    phone: '',
    industry: '',
    employees: '',
    currentSystem: '',
    monthlyHours: '',
    additionalRequirements: '',
    comments: '',
  });

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNext = () => {
    setStep(prev => Math.min(prev + 1, 4));
  };

  const handlePrev = () => {
    setStep(prev => Math.max(prev - 1, 1));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post('/send-mail', {
        subject: `Demo-Anfrage von ${formData.contactPerson}`,
        message: `
          Unternehmen: ${formData.companyName}
          Kontaktperson: ${formData.contactPerson}
          E-Mail: ${formData.email}
          Telefon: ${formData.phone}
          Branche: ${formData.industry}
          Mitarbeiter: ${formData.employees}
          Aktuelles System: ${formData.currentSystem}
          Monatliche Stunden: ${formData.monthlyHours}
          Zusätzliche Anforderungen: ${formData.additionalRequirements}
          Kommentare: ${formData.comments}
        `
      });

      if (response.status === 200) {
        setAlert({
          type: 'success',
          title: 'Anfrage erfolgreich gesendet!',
          message: 'Vielen Dank für Ihre Anfrage. Wir werden uns zeitnah bei Ihnen melden.'
        });
        setFormData({
          companyName: '',
          contactPerson: '',
          email: '',
          phone: '',
          industry: '',
          employees: '',
          currentSystem: '',
          monthlyHours: '',
          additionalRequirements: '',
          comments: '',
        });
        setFormDisabled(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setAlert({
        type: 'error',
        title: 'Fehler beim Senden',
        message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      });
    } finally {
      setLoading(false);
    }
  };
  const ProgressSteps = ({ currentStep }) => {
    return (
      <div className="max-w-2xl mx-auto mb-12">
        <div className="relative flex justify-between">
          {/* Connection Lines */}
          <div className="absolute top-1/2 left-0 right-0 h-0.5 bg-gray-200 -translate-y-1/2">
            <div 
              className="h-full bg-blue-600 transition-all duration-300"
              style={{ width: `${((currentStep - 1) / 3) * 100}%` }}
            />
          </div>
  
          {/* Steps */}
          {[
            { number: 1, label: 'Kontakt' },
            { number: 2, label: 'Unternehmen' },
            { number: 3, label: 'System' },
            { number: 4, label: 'Details' }
          ].map((step, index) => (
            <div key={index} className="relative flex flex-col items-center">
              <div className={`
                w-10 h-10 rounded-full flex items-center justify-center font-semibold text-sm
                transition-all duration-300
                ${currentStep >= step.number 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-white border-2 border-gray-200 text-gray-400'}
              `}>
                {step.number}
              </div>
              <span className={`
                absolute -bottom-6 text-xs font-medium whitespace-nowrap
                ${currentStep >= step.number ? 'text-blue-600' : 'text-gray-400'}
              `}>
                {step.label}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/50 py-24">
      <Helmet>
        <title>blub.ai - Demo anfordern</title>
        <meta name="description" content="Fordern Sie eine kostenlose Demo von blub.ai an und erleben Sie die Leistungsfähigkeit des virtuellen Telefonassistenten." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/demo" />
      </Helmet>

      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <motion.div 
          className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl"
          initial={{ scale: 0.9 }}
          animate={{ scale: [0.9, 1.1, 0.9] }}
          transition={{ duration: 8, repeat: Infinity, ease: "easeInOut" }}
        />
        <motion.div 
          className="absolute -right-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl"
          initial={{ scale: 1.1 }}
          animate={{ scale: [1.1, 0.9, 1.1] }}
          transition={{ duration: 8, repeat: Infinity, ease: "easeInOut", delay: 4 }}
        />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Demo anfordern
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Lernen Sie
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">blub.ai kennen</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Erfahren Sie mehr über unseren KI-Telefonassistenten und wie er Ihr Unternehmen unterstützen kann.
          </p>
        </div>

        {/* Progress Steps */}
        <ProgressSteps currentStep={step} />

        {/* Alert */}
        <AnimatePresence>
          {alert && (
            <div className="max-w-xl mx-auto mb-8">
              <CustomAlert
                type={alert.type}
                title={alert.title}
                message={alert.message}
                onClose={() => setAlert(null)}
              />
            </div>
          )}
        </AnimatePresence>

        <div className="max-w-xl mx-auto">
          <motion.form 
            onSubmit={handleSubmit}
            className="space-y-6 bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-gray-100"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <AnimatePresence mode="wait">
              <FormStep 
                step={step} 
                formData={formData} 
                handleChange={handleChange}
                disabled={formDisabled || loading}
              />
            </AnimatePresence>

            {/* Navigation Buttons */}
<div className="flex justify-between mt-8">
  <motion.button
    type="button"
    onClick={handlePrev}
    className={`px-6 py-3 rounded-lg font-medium transition-colors ${
      step === 1 
        ? 'opacity-0 pointer-events-none' 
        : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
    }`}
    disabled={step === 1 || formDisabled || loading}
    whileHover={{ scale: 1.005 }}
    whileTap={{ scale: 0.995 }}
  >
    Zurück
  </motion.button>

  {/* Hier ist die Korrektur */}
  <motion.button
    type="button" // Immer "button" als Typ verwenden
    onClick={(e) => {
      e.preventDefault(); // Verhindert ungewolltes Absenden
      if (step === 4) {
        handleSubmit(e);
      } else {
        handleNext();
      }
    }}
    className="px-6 py-3 rounded-lg font-medium text-white bg-blue-600 hover:bg-blue-700 transition-colors flex items-center"
    disabled={formDisabled || loading}
    whileHover={{ scale: 1.005 }}
    whileTap={{ scale: 0.995 }}
  >
    {loading ? (
      <>
        <Loader2 className="w-5 h-5 mr-2 animate-spin" />
        Wird gesendet...
      </>
    ) : step === 4 ? (
      'Anfrage senden'
    ) : (
      'Weiter'
    )}
  </motion.button>
</div>

            {/* Step Indicators */}
            <div className="flex justify-center space-x-2 mt-6">
              {[1, 2, 3, 4].map((i) => (
                <motion.div
                  key={i}
                  className={`w-2 h-2 rounded-full ${
                    step === i ? 'bg-blue-600' : 'bg-gray-300'
                  }`}
                  animate={{
                    scale: step === i ? 1.2 : 1
                  }}
                />
              ))}
            </div>

            {/* Form Step Labels */}
            <div className="text-center mt-4">
              <p className="text-sm text-gray-500">
                {step === 1 && 'Kontaktinformationen'}
                {step === 2 && 'Unternehmensinformationen'}
                {step === 3 && 'Aktuelle Situation'}
                {step === 4 && 'Zusätzliche Details'}
              </p>
            </div>

            {step === 4 && (
              <p className="mt-4 text-sm text-center text-gray-500">
                Mit dem Absenden stimmen Sie zu, dass wir Sie bezüglich Ihrer Anfrage kontaktieren dürfen.
              </p>
            )}
          </motion.form>

          {/* Feature Highlights */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-16">
            {[
              {
                icon: Clock,
                title: 'Schnelle Einrichtung',
                description: 'In wenigen Minuten einsatzbereit'
              },
              {
                icon: CheckCircle2,
                title: 'Kostenlose Demo',
                description: 'Testen Sie alle Features'
              },
              {
                icon: Users,
                title: 'Persönliche Beratung',
                description: 'Individuelle Lösungen'
              }
            ].map((feature, index) => (
              <div
                key={index}
                className="p-6 bg-white/80 backdrop-blur-sm rounded-xl border border-gray-100 text-center"
              >
                <div className="w-12 h-12 mx-auto bg-blue-50 rounded-lg flex items-center justify-center">
                  <feature.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="mt-4 font-semibold text-gray-900">
                  {feature.title}
                </h3>
                <p className="mt-2 text-sm text-gray-600">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DemoRequestForm;
