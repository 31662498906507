import React from 'react';
import { TableCell, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { Sms, Email, HelpOutline, ErrorOutline, CheckCircleOutline } from '@mui/icons-material';

const SummaryCell = ({ conversation, handleSmsIconClick }) => (
  <TableCell 
    sx={{ 
      textAlign: 'left', 
      padding: '16px 12px',
      backgroundColor: 'transparent',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Typography
        sx={{
          color: 'rgb(55 65 81)', // text-gray-700
          fontSize: '0.875rem',
          lineHeight: '1.5',
          letterSpacing: '0.01em',
          fontWeight: '400',
        }}
      >
        {conversation.summary?.length > 300 
          ? `${conversation.summary?.substring(0, 300)}...` 
          : conversation.summary}
      </Typography>

      {conversation.is_done === 1 && (
        <Box 
          sx={{ 
            display: 'flex', 
            gap: '8px',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          {conversation.answer_type === 'SMS' && (
            <Tooltip 
              title="SMS senden" 
              arrow 
              placement="top"
            >
              <IconButton 
                onClick={() => handleSmsIconClick(conversation.conversation_id)}
                sx={{
                  color: 'rgb(59 130 246)', // text-blue-500
                  padding: '4px',
                  '&:hover': {
                    backgroundColor: 'rgb(239 246 255)', // bg-blue-50
                    transform: 'scale(1.05)',
                    transition: 'all 0.2s ease-in-out',
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Sms sx={{ fontSize: '1.25rem' }} />
              </IconButton>
            </Tooltip>
          )}

          {conversation.answer_type === 'EMAIL' && (
            <Tooltip title="Email gesendet" arrow placement="top">
              <Email 
                sx={{ 
                  color: 'rgb(59 130 246)',
                  fontSize: '1.25rem',
                }} 
              />
            </Tooltip>
          )}

          {conversation.answer_type === 'NONE' ? (
            <Tooltip title="Keine Antwort gesendet" arrow placement="top">
              <HelpOutline 
                sx={{ 
                  color: 'rgb(245 158 11)', // amber-500
                  fontSize: '1.25rem',
                }} 
              />
            </Tooltip>
          ) : conversation.answer_success === null ? (
            <Tooltip title="In Bearbeitung" arrow placement="top">
              <ErrorOutline 
                sx={{ 
                  color: 'rgb(156 163 175)', // gray-400
                  fontSize: '1.25rem',
                }} 
              />
            </Tooltip>
          ) : conversation.answer_success ? (
            <Tooltip title="Erfolgreich" arrow placement="top">
              <CheckCircleOutline 
                sx={{ 
                  color: 'rgb(34 197 94)', // green-500
                  fontSize: '1.25rem',
                }} 
              />
            </Tooltip>
          ) : (
            <Tooltip title="Fehlgeschlagen" arrow placement="top">
              <ErrorOutline 
                sx={{ 
                  color: 'rgb(239 68 68)', // red-500
                  fontSize: '1.25rem',
                }} 
              />
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  </TableCell>
);

export default SummaryCell;
