import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Home, Layout, Users, Briefcase, LayoutDashboard, LogOut } from 'lucide-react';
import { supabase } from '../supabaseClient';

const ModernHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem('token');

  const navLinks = [
    { path: '/', icon: Home, label: 'Home' },
    { path: '/features', icon: Layout, label: 'Funktionen' },
    { path: '/contact', icon: Users, label: 'Kontakt' },
    { path: '/demo', icon: Briefcase, label: 'Demo/Angebot' },
  ];

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      localStorage.removeItem('token');
      navigate('/login');
    }
  };

  const isActivePath = (path) => location.pathname === path;

  return (
    <header className="sticky top-0 w-full z-50">
      {/* Glassmorphism background */}
      <div className="absolute inset-0 bg-white/70 backdrop-blur-lg border-b border-gray-200/20" />

      <nav className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Logo */}
          <Link to="/" className="flex-shrink-0">
            <img 
              src="https://img.ashub.de/blub-ai/blubbb.png" 
              alt="Logo"
              className="h-8 w-auto md:h-10" 
            />
          </Link>

          {/* Mobile menu button */}
          <button 
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-gray-100/50 transition-colors"
          >
            <Menu className="w-6 h-6 text-gray-600" />
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:gap-1">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`px-4 py-2 rounded-lg text-sm font-medium flex items-center gap-2 transition-all duration-200
                  ${isActivePath(link.path)
                    ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/20'
                    : 'text-gray-600 hover:bg-gray-100/50 hover:text-gray-900'
                  }`}
              >
                <link.icon className="w-4 h-4" />
                {link.label}
              </Link>
            ))}

            {/* Dashboard Button */}
            <Link
              to="/dashboard"
              className="ml-4 px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium flex items-center gap-2 
                hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
            >
              <LayoutDashboard className="w-4 h-4" />
              Dashboard
            </Link>

            {/* Logout Button */}
            {token && (
              <button
                onClick={handleLogout}
                className="ml-4 px-4 py-2 bg-red-100 text-red-600 rounded-lg text-sm font-medium flex items-center gap-2 
                  hover:bg-red-200 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                Abmelden
              </button>
            )}
          </div>
        </div>

        {/* Mobile Navigation */}
        {isOpen && (
          <div className="md:hidden absolute top-full left-0 w-full bg-white/80 backdrop-blur-lg border-b border-gray-200/20 p-4 space-y-2">
            {navLinks.map((link) => (
              <Link
                key={link.path}
                to={link.path}
                className={`px-4 py-3 rounded-lg text-sm font-medium flex items-center gap-2 transition-all duration-200
                  ${isActivePath(link.path)
                    ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/20'
                    : 'text-gray-600 hover:bg-gray-100/50 hover:text-gray-900'
                  }`}
                onClick={() => setIsOpen(false)}
              >
                <link.icon className="w-4 h-4" />
                {link.label}
              </Link>
            ))}

            <Link
              to="/dashboard"
              className="px-4 py-3 bg-blue-600 text-white rounded-lg text-sm font-medium flex items-center gap-2 
                hover:bg-blue-700 transition-colors shadow-lg shadow-blue-500/20"
              onClick={() => setIsOpen(false)}
            >
              <LayoutDashboard className="w-4 h-4" />
              Dashboard
            </Link>

            {token && (
              <button
                onClick={() => {
                  handleLogout();
                  setIsOpen(false);
                }}
                className="w-full px-4 py-3 bg-red-100 text-red-600 rounded-lg text-sm font-medium flex items-center gap-2 
                  hover:bg-red-200 transition-colors"
              >
                <LogOut className="w-4 h-4" />
                Abmelden
              </button>
            )}
          </div>
        )}
      </nav>
    </header>
  );
};

export default ModernHeader;