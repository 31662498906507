import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Tabs,
  Tab,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControl,
  InputLabel,
  CircularProgress,
  TextField,
  List,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
} from '@mui/material';
import { Search, Chat, Done } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';
import { io } from 'socket.io-client';
import 'react-h5-audio-player/lib/styles.css';
import ConversationActions from '../ConversationActions';
import SmsDetailsForm from '../SmsDetailsForm';
import ConversationRow from './ConversationRow';
import { renderResultItem } from './utils';

const ConversationList = () => {
  const [conversations, setConversations] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState('Active');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [openSmsDialog, setOpenSmsDialog] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await axiosInstance.get('/attributes');
        setAttributes(response.data.filter((attribute) => attribute.is_active));
      } catch (error) {
        console.error('There was an error fetching the attributes!', error);
      }
    };

    const fetchConversations = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/conversations', {
          params: {
            page,
            per_page: perPage,
            status: activeTab.toLowerCase(),
            category: selectedCategory,
          },
        });
        setConversations(response.data.conversations);
        setTotalPages(Math.ceil(response.data.total / perPage));
      } catch (error) {
        console.error('There was an error fetching the conversations!', error);
      }
      setLoading(false);
    };

    fetchAttributes();
    fetchConversations();

    const socket = io('https://blub.ai', {
      path: '/socket.io',
      transports: ['websocket'],
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
    });

    socket.on('update', (data) => {
      if (data.status === 'done') {
        setConversations((prevConversations) =>
          prevConversations.filter(
            (conversation) => conversation.conversation_id !== data.conversation_id
          )
        );
        if (activeTab === 'Done') {
          setConversations((prevConversations) => [...prevConversations, data]);
        }
      }
    });

    socket.on('update_conversation', (data) => {
      setConversations((prevConversations) => {
        const conversationExists = prevConversations.find(
          (conversation) => conversation.conversation_id === data.conversation_id
        );
        if (conversationExists) {
          return prevConversations.map((conversation) =>
            conversation.conversation_id === data.conversation_id
              ? { ...conversation, ...data }
              : conversation
          );
        } else {
          return [data, ...prevConversations];
        }
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [page, perPage, activeTab, selectedCategory]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get('/categories');
        setCategories(response.data.categories);
      } catch (error) {
        console.error('There was an error fetching the categories!', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchQuery.trim() === '') {
        setSearchResults([]);
        return;
      }

      setSearching(true);

      try {
        const response = await axiosInstance.get('/search/conversations', {
          params: { q: searchQuery },
        });
        setSearchResults(response.data);
      } catch (error) {
        console.error('There was an error fetching the search results!', error);
        setSearchResults([]);
      }

      setSearching(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchSearchResults();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  const handlePageChange = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const handleMarkAsDone = useCallback((conversation_id) => {
    setConversations((prevConversations) =>
      prevConversations.filter(
        (conversation) => conversation.conversation_id !== conversation_id
      )
    );
  }, []);

  const handleOpenDialog = useCallback((conversation) => {
    setSelectedConversation(conversation);
    setOpenDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setOpenDialog(false);
    setSelectedConversation(null);
  }, []);

  const handleCategoryChange = useCallback((event) => {
    setSelectedCategory(event.target.value);
  }, []);

  const handleSmsIconClick = useCallback((conversationId) => {
    setSelectedConversationId(conversationId);
    setOpenSmsDialog(true);
  }, []);

  const handleCloseSmsDialog = useCallback(() => {
    setOpenSmsDialog(false);
    setSelectedConversationId(null);
  }, []);

  const handleCopyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log('Copied to clipboard successfully!');
      },
      (err) => {
        console.error('Failed to copy to clipboard: ', err);
      }
    );
  }, []);

  const handleSearchChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, []);

  return (
    <Container 
      maxWidth="xl" 
      sx={{ 
        padding: isMobile ? 2 : 4,
        position: 'relative',
      }}
    >
      {/* Background decorative elements */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: 'hidden',
          zIndex: -1,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '-10%',
            right: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.1) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-10%',
            left: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.05) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
          }}
        />
      </Box>

      {/* Header */}
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        component="h1"
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          fontWeight: '700',
          color: 'rgb(17 24 39)',
          position: 'relative',
        }}
      >
        Konversationen
        <Box
          sx={{
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          }}
        />
      </Typography>

      {/* Controls Section */}
      <Paper
        elevation={0}
        sx={{
          mb: 4,
          p: 2,
          background: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* Tabs */}
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{
              minHeight: 'unset',
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              '& .MuiTab-root': {
                minHeight: '40px',
                padding: '8px 16px',
                borderRadius: '0.5rem',
                color: 'rgb(107 114 128)',
                fontSize: '0.875rem',
                fontWeight: '500',
                textTransform: 'none',
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: 'rgb(243 244 246)',
                  color: 'rgb(31 41 55)',
                },
                '&.Mui-selected': {
                  backgroundColor: 'rgb(239 246 255)',
                  color: 'rgb(59 130 246)',
                },
              },
            }}
          >
            <Tab 
              icon={<Chat sx={{ fontSize: '1.25rem', mr: 1 }} />} 
              label="Neu" 
              value="Active"
              iconPosition="start"
            />
            <Tab 
              icon={<Done sx={{ fontSize: '1.25rem', mr: 1 }} />} 
              label="Abgeschlossen" 
              value="Done"
              iconPosition="start"
            />
          </Tabs>

          {/* Category Select */}
          <FormControl 
            size="small"
            sx={{ 
              minWidth: 200,
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                backgroundColor: 'white',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(59 130 246)',
                },
              },
            }}
          >
            <InputLabel>Kategorie</InputLabel>
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              label="Kategorie"
            >
              <MenuItem value="">
                <em>Alle</em>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.category_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Search */}
          <TextField
            variant="outlined"
            placeholder="Suche..."
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{
              width: isMobile ? '100%' : '300px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                backgroundColor: 'white',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(59 130 246)',
                },
              },
            }}
            InputProps={{
              startAdornment: <Search sx={{ color: 'rgb(156 163 175)', mr: 1 }} />,
            }}
          />
        </Box>
      </Paper>

      {/* Search Results */}
      {searching ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress sx={{ color: 'rgb(59 130 246)' }} />
        </Box>
      ) : searchResults.length > 0 && (
        <Paper
          elevation={0}
          sx={{
            mb: 4,
            p: 3,
            borderRadius: '1rem',
            border: '1px solid rgba(229, 231, 235, 1)',
            backgroundColor: 'white',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
          }}
        >
          <Typography 
            variant="h6" 
            sx={{ 
              mb: 2,
              color: 'rgb(17 24 39)',
              fontWeight: '600',
              fontSize: '1.125rem',
            }}
          >
            Suchergebnisse
          </Typography>
          <List sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {searchResults.map(renderResultItem)}
          </List>
        </Paper>
      )}

      {/* Main Content */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 8 }}>
          <CircularProgress sx={{ color: 'rgb(59 130 246)' }} />
        </Box>
      ) : (
        <Paper
          elevation={0}
          sx={{
            borderRadius: '1rem',
            border: '1px solid rgba(229, 231, 235, 1)',
            backgroundColor: 'white',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
            overflow: 'hidden',
          }}
        >
          <TableContainer>
            <Table aria-label="conversations table" size="small">
              <TableHead>
                <TableRow>
                  {/* Table headers with consistent styling */}
                  {['Aktionen', 'Tel-Nr.', ...attributes.map(a => a.attribute_name), 'Anruf am', 'Zusammenfassung', 'Audio', ...(activeTab !== 'Active' ? ['Bearbeiter'] : [])].map((header, index) => (
                    <TableCell
                      key={header}
                      sx={{
                        backgroundColor: 'rgb(249 250 251)',
                        color: 'rgb(17 24 39)',
                        fontWeight: '600',
                        fontSize: '0.875rem',
                        padding: '16px',
                        borderBottom: '2px solid rgb(229 231 235)',
                        ...(index === 1 && { width: '120px' }),
                      }}
                    >
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {conversations.map((conversation) => (
                  <ConversationRow
                    key={conversation.conversation_id}
                    conversation={conversation}
                    attributes={attributes}
                    handleOpenDialog={handleOpenDialog}
                    handleCopyToClipboard={handleCopyToClipboard}
                    handleSmsIconClick={handleSmsIconClick}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      {/* Pagination */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          gap: 2,
          mt: 4,
        }}
      >
        <Button
          variant="contained"
          disabled={page === 1}
          onClick={() => handlePageChange(page - 1)}
          sx={{
            backgroundColor: 'white',
            color: 'rgb(59 130 246)',
            border: '1px solid rgb(229 231 235)',
            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
            '&:hover': {
              backgroundColor: 'rgb(243 244 246)',
              boxShadow: '0 2px 4px -1px rgb(0 0 0 / 0.05)',
            },
            '&:disabled': {
              backgroundColor: 'rgb(243 244 246)',
              color: 'rgb(156 163 175)',
            },
          }}
        >
          Zurück
        </Button>
        <Button
          variant="contained"
          disabled={page === totalPages}
          onClick={() => handlePageChange(page + 1)}
          sx={{
            backgroundColor: 'rgb(59 130 246)',
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgb(37 99 235)',
            },
            '&:disabled': {
              backgroundColor: 'rgb(219 234 254)',
              color: 'rgb(147 197 253)',
            },
          }}
        >
          Weiter
        </Button>
      </Box>

      {/* Dialogs */}
      {selectedConversation && (
        <ConversationActions
          conversation={selectedConversation}
          open={openDialog}
          onClose={handleCloseDialog}
          onMarkAsDone={handleMarkAsDone}
        />
      )}
      {openSmsDialog && (
        <SmsDetailsForm
          conversationId={selectedConversationId}
          open={openSmsDialog}
          onClose={handleCloseSmsDialog}
        />
      )}
    </Container>
  );
};

export default ConversationList;
