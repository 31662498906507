import React from 'react';
import { TableCell, Box, IconButton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const PhoneNumberCell = ({ phoneNumber, handleCopyToClipboard }) => (
  <TableCell 
    sx={{ 
      textAlign: 'left', 
      padding: '16px 12px',
      backgroundColor: 'transparent',
    }}
  >
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: '8px',
        color: 'rgb(31 41 55)', // text-gray-800
        fontSize: '0.875rem',
        fontWeight: '500',
      }}
    >
      <span style={{ letterSpacing: '0.01em' }}>{phoneNumber}</span>
      <Tooltip 
        title="Kopieren" 
        arrow
        placement="top"
      >
        <IconButton 
          onClick={() => handleCopyToClipboard(phoneNumber)} 
          size="small"
          sx={{
            color: 'rgb(107 114 128)', // text-gray-500
            padding: '4px',
            '&:hover': {
              backgroundColor: 'rgb(243 244 246)', // bg-gray-100
              color: 'rgb(59 130 246)', // text-blue-500
              transform: 'scale(1.05)',
              transition: 'all 0.2s ease-in-out',
            },
            transition: 'all 0.2s ease-in-out',
          }}
        >
          <ContentCopy 
            sx={{ 
              fontSize: '1rem',
            }} 
          />
        </IconButton>
      </Tooltip>
    </Box>
  </TableCell>
);

export default PhoneNumberCell;
