import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Check, Building2, LibraryBig, Network, Store, Home, Dumbbell, GraduationCap, Hotel, Building, MonitorSmartphone, ShieldCheck, Plane } from 'lucide-react';
import './applications.css';
const applications = [
  { 
    title: 'Arztpraxen',
    icon: "🏥",
    color: "text-emerald-600",
    bgColor: "bg-emerald-50",
    benefits: [
      'Dashboard für alle Patientenanfragen',
      'Notfallerkennung und automatische Weiterleitung',
      'Automatisierte Terminvereinbarung und -bestätigung',
      'Beantwortung von Standardanfragen (Öffnungszeiten, Versicherungen)',
    ]
  },
  { 
    title: 'Rechtsanwaltskanzleien',
    icon: LibraryBig,
    color: "text-blue-600",
    bgColor: "bg-blue-50",
    benefits: [
      'Mandanteninformationen automatisch erfassen',
      'Anfragen nach Priorität sortieren',
      'Routineanfragen automatisieren und vorqualifizieren',
      'Komplexe Fälle an spezialisierte Anwälte weiterleiten',
    ]
  },
  { 
    title: 'Kundenservicezentren',
    icon: Network,
    color: "text-violet-600",
    bgColor: "bg-violet-50",
    benefits: [
      'Beantwortung häufiger Kundenanfragen',
      'Beschwerden entgegennehmen und eskalieren',
      'Allgemeine Informationen bereitstellen',
      'Arbeitslast des Teams reduzieren durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Einzelhandel',
    icon: Store,
    color: "text-orange-600",
    bgColor: "bg-orange-50",
    benefits: [
      'Kundenanfragen und Bestellungen automatisiert verwalten',
      'Produktverfügbarkeiten in Echtzeit mitteilen',
      'Aufnahme von Bestellungen und Rückfragen',
      'Informationen zu Rückgaberichtlinien und Öffnungszeiten',
    ]
  },
  { 
    title: 'Immobilienbüros',
    icon: Home,
    color: "text-indigo-600",
    bgColor: "bg-indigo-50",
    benefits: [
      'Vereinbarung von Besichtigungen',
      'Automatisierte Beantwortung von Immobilienanfragen',
      'Qualifizierung potenzieller Kunden',
      'Zeiteinsparung durch Automatisierung wiederkehrender Anfragen',
    ]
  },
  { 
    title: 'Fitnessstudios',
    icon: Dumbbell,
    color: "text-rose-600",
    bgColor: "bg-rose-50",
    benefits: [
      'Anfragen zu Kurszeiten und Mitgliedschaften beantworten',
      'Mitgliederverwaltung automatisieren',
      'Registrierung neuer Mitglieder ohne Personalaufwand',
      'Verfügbarkeit von Kursplätzen und Räumen in Echtzeit anzeigen',
    ]
  },
  { 
    title: 'Bildungsinstitutionen',
    icon: GraduationCap,
    color: "text-cyan-600",
    bgColor: "bg-cyan-50",
    benefits: [
      'Automatisierte Verwaltung von Kursanfragen',
      'Einschreibungen und allgemeine Informationen automatisch verarbeiten',
      'Entlastung des Verwaltungspersonals',
      'Verbesserte Informationsverarbeitung durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Hotels',
    icon: Hotel,
    color: "text-amber-600",
    bgColor: "bg-amber-50",
    benefits: [
      'Bearbeitung von Buchungsanfragen rund um die Uhr',
      'Informationen zu Zimmerverfügbarkeiten in Echtzeit',
      'Verwaltung spezieller Gästeanfragen',
      'Optimierung des Gästeservices durch KI-Unterstützung',
    ]
  },
  { 
    title: 'Finanzdienstleistungen',
    icon: Building,
    color: "text-emerald-600",
    bgColor: "bg-emerald-50",
    benefits: [
      'Verwaltung von Kundenanfragen zu Kontoinformationen',
      'Automatisierte Terminvereinbarungen für Beratungsgespräche',
      'Verwaltung von Routinedienstleistungen ohne manuelles Eingreifen',
      'Verbesserte Kundenzufriedenheit durch schnellere Reaktionszeiten',
    ]
  },
  { 
    title: 'IT-Support',
    icon: MonitorSmartphone,
    color: "text-blue-600",
    bgColor: "bg-blue-50",
    benefits: [
      'Erfassung und Kategorisierung technischer Anfragen',
      'Erste Lösungsvorschläge durch KI-basierte Supportsysteme',
      'Automatisierte Erstellung von Support-Tickets',
      'Effizienzsteigerung durch Vorqualifizierung der Anfragen',
    ]
  },
  { 
    title: 'Versicherungen',
    icon: ShieldCheck,
    color: "text-indigo-600",
    bgColor: "bg-indigo-50",
    benefits: [
      'Verwaltung von Anfragen zu Policen und Schadenmeldungen',
      'Automatisierung der Bearbeitung von Routineanfragen',
      'Eskalation dringender Anfragen an die richtigen Abteilungen',
      'Verbesserung der Kundenzufriedenheit durch sofortige Rückmeldungen',
    ]
  },
  { 
    title: 'Reisebüros',
    icon: Plane,
    color: "text-purple-600",
    bgColor: "bg-purple-50",
    benefits: [
      'Automatisierte Verwaltung von Reisebuchungen',
      'Bereitstellung von Informationen zu Verfügbarkeiten und Angeboten',
      'Schnelle Bearbeitung von Kundenanfragen durch KI-Unterstützung',
      'Erhöhung der Servicequalität bei gleichzeitiger Entlastung des Personals',
    ]
  },
];

const Applications = () => {
  const [activeCategory, setActiveCategory] = useState('Arztpraxen');

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/30">
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute w-[800px] h-[800px] bg-blue-50/50 rounded-full blur-3xl -right-40 -top-40" />
        <div className="absolute w-[600px] h-[600px] bg-blue-50/30 rounded-full blur-3xl -left-20 bottom-0" />
      </div>

      <div className="relative px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Anwendungsbereiche
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            Entdecken Sie, wie unsere KI-Lösung in verschiedenen Branchen eingesetzt werden kann.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Category Sidebar - scrollable on mobile, fixed height on desktop */}
          <div className="lg:col-span-4 overflow-auto lg:h-[600px] space-y-2 pr-4 custom-scrollbar">
            {applications.map((app) => (
              <button
                key={app.title}
                onClick={() => setActiveCategory(app.title)}
                className={`w-full text-left px-4 py-3 rounded-xl transition-all duration-200 flex items-center gap-3
                  ${activeCategory === app.title 
                    ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/20' 
                    : 'bg-white hover:bg-blue-50 text-gray-700'}`}
              >
                {typeof app.icon === 'string' ? (
                  <span className="text-2xl">{app.icon}</span>
                ) : (
                  <app.icon className={`w-5 h-5 ${activeCategory === app.title ? 'text-white' : app.color}`} />
                )}
                <span className="font-medium">{app.title}</span>
              </button>
            ))}
          </div>

          {/* Content Area */}
          <div className="lg:col-span-8">
            <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-xl border border-gray-100">
              {applications.map((app) => (
                app.title === activeCategory && (
                  <div key={app.title} className="space-y-8">
                    <div className="flex items-center gap-4">
                      {typeof app.icon === 'string' ? (
                        <span className="text-4xl">{app.icon}</span>
                      ) : (
                        <div className={`p-3 ${app.bgColor} rounded-xl`}>
                          <app.icon className={`w-8 h-8 ${app.color}`} />
                        </div>
                      )}
                      <h3 className="text-2xl font-semibold text-gray-900">{app.title}</h3>
                    </div>
                    
                    <div className="grid gap-4 sm:grid-cols-2">
                      {app.benefits.map((benefit, index) => (
                        <div 
                          key={index}
                          className={`flex items-start gap-3 p-4 rounded-xl ${app.bgColor} hover:bg-opacity-75 transition-colors`}
                        >
                          <div className={`p-1 ${app.color} bg-white rounded-full`}>
                            <Check className="w-4 h-4" />
                          </div>
                          <p className="text-gray-700">{benefit}</p>
                        </div>
                      ))}
                    </div>

                    <div className="pt-6">
                      <Link
                        to="/demo"
                        className="inline-flex items-center px-6 py-3 text-sm font-semibold text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
                      >
                        Demo für {app.title} anfragen
                      </Link>
                    </div>
                  </div>
                )
              ))}
            </div>
          </div>
        </div>

        {/* Hidden SEO content */}
        <div className="sr-only">
          {applications.map((app) => (
            <div key={app.title}>
              <h3>{app.title}</h3>
              <ul>
                {app.benefits.map((benefit, index) => (
                  <li key={index}>{benefit}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
    
  );
  
};

export default Applications;