import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Paper } from '@mui/material';
import axiosInstance from '../axiosConfig';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';

const CustomerManagement = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customerName, setCustomerName] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    const customer = customers.find(c => c.id === customerId);
    setSelectedCustomer(customerId);
    setCustomerName(customer ? customer.name : '');
  };

  const handleSave = async () => {
    try {
      if (selectedCustomer) {
        await axiosInstance.put(`/admin/customers/${selectedCustomer}`, { name: customerName });
      } else {
        await axiosInstance.post('/admin/customers', { name: customerName });
      }
      fetchCustomers();
      setSelectedCustomer('');
      setCustomerName('');
    } catch (error) {
      console.error('Error saving customer:', error);
    }
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        Kundenverwaltung
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <FormControl 
          fullWidth 
          sx={{ 
            ...inputStyles,
            mb: 3,
          }}
        >
          <InputLabel>Kunde</InputLabel>
          <Select 
            value={selectedCustomer} 
            onChange={handleCustomerChange} 
            label="Kunde"
          >
            <MenuItem 
              value=""
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'rgb(59 130 246)',
              }}
            >
              <AddIcon fontSize="small" />
              <em>Neuen Kunden erstellen</em>
            </MenuItem>
            {customers.map((customer) => (
              <MenuItem 
                key={customer.id} 
                value={customer.id}
                sx={{
                  borderRadius: '0.25rem',
                  '&:hover': {
                    backgroundColor: 'rgb(243 244 246)',
                  },
                }}
              >
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Kundenname"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          sx={{
            ...inputStyles,
            mb: 4,
          }}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button 
            variant="contained"
            onClick={handleSave}
            startIcon={<SaveIcon />}
            sx={{
              backgroundColor: 'rgb(59 130 246)',
              color: 'white',
              textTransform: 'none',
              fontWeight: '500',
              padding: '0.5rem 2rem',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(37 99 235)',
              },
              '&:disabled': {
                backgroundColor: 'rgb(219 234 254)',
                color: 'rgb(147 197 253)',
              },
            }}
          >
            {selectedCustomer ? 'Aktualisieren' : 'Erstellen'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CustomerManagement;
