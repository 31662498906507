import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Paper, Chip } from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import axiosInstance from '../axiosConfig';

const TemplateManagement = () => {
  const [templates, setTemplates] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [activeAttributes, setActiveAttributes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const contentRef = useRef(null);

  useEffect(() => {
    fetchCustomers();
    if (selectedCustomer) {
      fetchTemplates(selectedCustomer);
      fetchActiveAttributes(selectedCustomer);
    }
  }, [selectedCustomer]);

  const fetchTemplates = async (customerId) => {
    try {
      const response = await axiosInstance.get('/get-templates', {
        params: { customer_id: customerId }
      });
      setTemplates(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Vorlagen:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der Kunden:', error);
    }
  };

  const fetchActiveAttributes = async (customerId) => {
    try {
      const response = await axiosInstance.get('/active-attributes', { params: { customer_id: customerId } });
      setActiveAttributes(response.data);
    } catch (error) {
      console.error('Fehler beim Abrufen der aktiven Attribute:', error);
    }
  };

  const handleTemplateChange = (event) => {
    const templateId = event.target.value;
    const template = templates.find(t => t.id === templateId);
    setSelectedTemplate(templateId);
    setTemplateName(template ? template.name : '');
    setTemplateContent(template ? template.template : '');
  };

  const handleCustomerChange = (event) => {
    const customerId = event.target.value;
    setSelectedCustomer(customerId);
    fetchTemplates(customerId);
    fetchActiveAttributes(customerId);
  };

  const handleSave = async () => {
    try {
      if (selectedTemplate) {
        await axiosInstance.put(`/edit-template/${selectedTemplate}`, { name: templateName, template: templateContent });
      } else {
        await axiosInstance.post('/create-template', { name: templateName, template: templateContent, customer_id: selectedCustomer });
      }
      fetchTemplates(selectedCustomer);
      setSelectedTemplate('');
      setTemplateName('');
      setTemplateContent('');
      setSelectedCustomer('');
    } catch (error) {
      console.error('Fehler beim Speichern der Vorlage:', error);
    }
  };

  const handleDelete = async () => {
    try {
      if (selectedTemplate && selectedCustomer) {
        await axiosInstance.delete(`/delete-template/${selectedTemplate}`, {
          data: { customer_id: selectedCustomer }
        });
        fetchTemplates(selectedCustomer);
        setSelectedTemplate('');
        setTemplateName('');
        setTemplateContent('');
        setSelectedCustomer('');
      }
    } catch (error) {
      console.error('Fehler beim Löschen der Vorlage:', error);
    }
  };

  const insertPlaceholder = (placeholder) => {
    const startPos = contentRef.current.selectionStart;
    const endPos = contentRef.current.selectionEnd;
    const newContent = `${templateContent.substring(0, startPos)}{${placeholder}}${templateContent.substring(endPos)}`;
    setTemplateContent(newContent);
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        Vorlagenverwaltung
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <FormControl fullWidth sx={{ ...inputStyles, mb: 3 }}>
          <InputLabel>Kunde</InputLabel>
          <Select 
            value={selectedCustomer} 
            onChange={handleCustomerChange} 
            label="Kunde"
          >
            {customers.map((customer) => (
              <MenuItem 
                key={customer.id} 
                value={customer.id}
                sx={{
                  borderRadius: '0.25rem',
                  '&:hover': {
                    backgroundColor: 'rgb(243 244 246)',
                  },
                }}
              >
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ ...inputStyles, mb: 3 }}>
          <InputLabel>Vorlage</InputLabel>
          <Select 
            value={selectedTemplate} 
            onChange={handleTemplateChange} 
            label="Vorlage"
          >
            <MenuItem 
              value=""
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'rgb(59 130 246)',
              }}
            >
              <AddIcon fontSize="small" />
              <em>Neue Vorlage erstellen</em>
            </MenuItem>
            {templates.map((template) => (
              <MenuItem 
                key={template.id} 
                value={template.id}
                sx={{
                  borderRadius: '0.25rem',
                  '&:hover': {
                    backgroundColor: 'rgb(243 244 246)',
                  },
                }}
              >
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {activeAttributes.length > 0 && (
          <Box 
            sx={{ 
              mb: 3,
              p: 3,
              backgroundColor: 'rgb(249 250 251)',
              borderRadius: '0.5rem',
            }}
          >
            <Typography
              variant="h6"
              sx={{
                color: 'rgb(17 24 39)',
                fontWeight: '600',
                mb: 2,
                fontSize: '0.875rem',
              }}
            >
              Verfügbare Platzhalter:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
              {activeAttributes.map(attr => (
                <Chip
                  key={attr}
                  label={`{${attr}}`}
                  onClick={() => insertPlaceholder(attr)}
                  sx={{
                    backgroundColor: 'white',
                    border: '1px solid rgb(229 231 235)',
                    '&:hover': {
                      backgroundColor: 'rgb(243 244 246)',
                    },
                    cursor: 'pointer',
                  }}
                />
              ))}
            </Box>
            <Typography 
              variant="body2"
              sx={{
                color: 'rgb(107 114 128)',
                fontSize: '0.75rem',
              }}
            >
              Verwenden Sie diese Platzhalter in Ihrem Vorlageninhalt. Zum Beispiel wird "Hallo &#123;name&#125;" durch den tatsächlichen Namen ersetzt.
            </Typography>
          </Box>
        )}

        <TextField
          fullWidth
          label="Vorlagenname"
          value={templateName}
          onChange={(e) => setTemplateName(e.target.value)}
          sx={{ ...inputStyles, mb: 3 }}
        />

        <TextField
          fullWidth
          inputRef={contentRef}
          label="Vorlageninhalt"
          value={templateContent}
          onChange={(e) => setTemplateContent(e.target.value)}
          multiline
          rows={4}
          sx={{ ...inputStyles, mb: 4 }}
        />

        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={handleSave}
            startIcon={<SaveIcon />}
            sx={{
              backgroundColor: 'rgb(59 130 246)',
              color: 'white',
              textTransform: 'none',
              fontWeight: '500',
              padding: '0.5rem 2rem',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(37 99 235)',
              },
            }}
          >
            Speichern
          </Button>
          <Button
            variant="contained"
            onClick={handleDelete}
            startIcon={<DeleteIcon />}
            sx={{
              backgroundColor: 'rgb(239 68 68)',
              color: 'white',
              textTransform: 'none',
              fontWeight: '500',
              padding: '0.5rem 2rem',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(220 38 38)',
              },
            }}
          >
            Löschen
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TemplateManagement;
