import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Snackbar, Alert, Paper } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, Key as KeyIcon } from '@mui/icons-material';
import axiosInstance from '../axiosConfig';

const ApiKeyManagement = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    fetchApiKeys();
    fetchCustomers();
  }, []);

  const fetchApiKeys = async () => {
    try {
      const response = await axiosInstance.get('/admin/get-keys');
      setApiKeys(response.data);
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Error fetching customers:', error);
    }
  };

  const handleCreateApiKey = async () => {
    if (!selectedCustomer) {
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axiosInstance.post('/admin/create-key', null, {
        params: {
          'api-customer': selectedCustomer
        }
      });
      setNewApiKey(response.data.api_key);
      fetchApiKeys();
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  const handleDeleteApiKey = async (apiKey) => {
    try {
      await axiosInstance.delete('/admin/delete-key', {
        params: {
          'api-key': apiKey
        }
      });
      fetchApiKeys();
    } catch (error) {
      console.error('Error deleting API key:', error);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const filteredApiKeys = apiKeys.filter(key => key.customer_id === selectedCustomer);

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', mx: 'auto' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        API Key Management
      </Typography>

      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <FormControl fullWidth sx={{ ...inputStyles, mb: 3 }}>
          <InputLabel>Customer</InputLabel>
          <Select 
            value={selectedCustomer} 
            onChange={(e) => setSelectedCustomer(e.target.value)} 
            label="Customer"
          >
            {customers.map((customer) => (
              <MenuItem 
                key={customer.id} 
                value={customer.id}
                sx={{
                  borderRadius: '0.25rem',
                  '&:hover': {
                    backgroundColor: 'rgb(243 244 246)',
                  },
                }}
              >
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <Button
            variant="contained"
            onClick={handleCreateApiKey}
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: 'rgb(59 130 246)',
              color: 'white',
              textTransform: 'none',
              fontWeight: '500',
              padding: '0.5rem 2rem',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(37 99 235)',
              },
            }}
          >
            Create API Key
          </Button>
        </Box>

        {newApiKey && (
          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 4,
              borderRadius: '0.5rem',
              backgroundColor: 'rgb(243 244 246)',
              border: '1px dashed rgb(209 213 219)',
            }}
          >
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: 'rgb(17 24 39)',
                fontWeight: '500',
              }}
            >
              <KeyIcon sx={{ color: 'rgb(59 130 246)' }} />
              New API Key: {newApiKey}
            </Typography>
          </Paper>
        )}

        <Typography
          variant="h6"
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '600',
            mb: 3,
            fontSize: '1rem',
          }}
        >
          Existing API Keys
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {filteredApiKeys.length > 0 ? (
            filteredApiKeys.map((key) => (
              <Paper
                key={key.api_key}
                elevation={0}
                sx={{
                  p: 2,
                  borderRadius: '0.5rem',
                  border: '1px solid rgb(229 231 235)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'white',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgb(55 65 81)',
                    fontSize: '0.875rem',
                    fontFamily: 'monospace',
                  }}
                >
                  {key.api_key}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => handleDeleteApiKey(key.api_key)}
                  startIcon={<DeleteIcon />}
                  sx={{
                    backgroundColor: 'rgb(239 68 68)',
                    color: 'white',
                    textTransform: 'none',
                    fontWeight: '500',
                    borderRadius: '0.5rem',
                    '&:hover': {
                      backgroundColor: 'rgb(220 38 38)',
                    },
                  }}
                >
                  Delete
                </Button>
              </Paper>
            ))
          ) : (
            <Typography
              sx={{
                color: 'rgb(107 114 128)',
                textAlign: 'center',
                fontStyle: 'italic',
              }}
            >
              No API keys found for this customer.
            </Typography>
          )}
        </Box>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="warning"
          sx={{
            borderRadius: '0.5rem',
            backgroundColor: 'white',
            color: 'rgb(234 179 8)',
            '& .MuiAlert-icon': {
              color: 'rgb(234 179 8)',
            },
          }}
        >
          Bitte wählen Sie einen Kunden aus, bevor Sie einen API-Schlüssel erstellen.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ApiKeyManagement;
