import React from 'react';
import { 
  Phone, 
  Brain, 
  TrendingUp, 
  Settings, 
  FileText, 
  Cloud,
  ArrowRight
} from 'lucide-react';

const features = [
  { 
    icon: Phone, 
    title: 'Vollautomatisierte Anrufannahme', 
    description: 'Unser KI-basierter Anrufbeantworter nimmt Anrufe automatisch entgegen und hilft Ihren Kunden schnell, wodurch die Effizienz Ihrer Praxis erhöht wird.',
    highlight: '24/7 verfügbar'
  },
  { 
    icon: Brain, 
    title: 'Intelligente Gesprächsführung durch KI', 
    description: 'Mit unserer fortschrittlichen künstlichen Intelligenz liefert unser System präzise und hilfreiche Antworten auf Kundenanfragen.',
    highlight: 'Modernste KI-Technologie'
  },
  { 
    icon: TrendingUp, 
    title: 'Effizienzsteigerung', 
    description: 'Automatisieren Sie repetitive Aufgaben und steigern Sie die Produktivität Ihres Teams erheblich.',
    highlight: 'Bis zu 40% Zeitersparnis'
  },
  { 
    icon: Settings, 
    title: 'Konfigurierbare Abfragen', 
    description: 'Passen Sie unsere Lösung vollständig an die spezifischen Bedürfnisse und Prozesse Ihres Betriebes an.',
    highlight: 'Individuell anpassbar'
  },
  { 
    icon: FileText, 
    title: 'Echtzeit-Transkription', 
    description: 'Bearbeiten Sie Anfragen effizient auf einer zentralen Plattform mit Echtzeit-Transkription und detaillierten Anrufanalysen.',
    highlight: 'Sofortige Verfügbarkeit'
  },
  { 
    icon: Cloud, 
    title: 'Integration in bestehende Systeme', 
    description: 'Unsere cloudbasierte Lösung ist von überall zugänglich und integriert sich nahtlos in Ihre bestehende Infrastruktur.',
    highlight: 'Keine Installation nötig'
  },
];

const Features = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/50 py-24">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
        <div className="absolute -right-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Unsere Funktionen
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Alles was Sie brauchen
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">für Ihre Kommunikation</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Unsere Lösung bietet alle wichtigen Funktionen, die Sie für eine optimale Anrufverwaltung benötigen
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 mt-16 sm:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <div
              key={index}
              className="group relative bg-white/80 backdrop-blur rounded-2xl p-8 border border-gray-100 hover:shadow-xl transition-all duration-300 hover:scale-105"
            >
              <div className="relative">
                <div className="p-3 w-14 h-14 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
                  <feature.icon className="w-8 h-8 text-blue-600" />
                </div>
                <div className="absolute -inset-1 bg-blue-50 rounded-lg blur opacity-0 group-hover:opacity-40 transition-opacity" />
              </div>
              
              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                {feature.title}
              </h3>
              
              <p className="mt-2 text-gray-600">
                {feature.description}
              </p>

              <div className="mt-6 flex items-center text-sm font-medium text-blue-600">
                <span className="bg-blue-50 px-3 py-1 rounded-full">
                  {feature.highlight}
                </span>
                <ArrowRight className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;