import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Clock, Bot, Shield } from 'lucide-react';

const Hero = () => {
  const benefits = [
    {
      icon: Phone,
      title: "Nie wieder verpasste Anrufe",
      description: "24/7 professionelle Anrufentgegennahme"
    },
    {
      icon: Clock,
      title: "Zeit sparen",
      description: "Automatische Terminvereinbarung & Priorisierung"
    },
    {
      icon: Bot,
      title: "KI-gestützte Kommunikation",
      description: "Natürliche Gesprächsführung durch modernste KI"
    },
    {
      icon: Shield,
      title: "Höchste Datensicherheit",
      description: "DSGVO-konform & in Deutschland gehostet"
    }
  ];

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-blue-50/50 to-white">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -right-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
        <div className="absolute -left-1/4 top-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
      </div>

      <div className="relative mx-auto max-w-7xl">
        {/* Main Hero Section */}
        <div className="px-4 pt-20 pb-16 sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            {/* Left Column - Content */}
            <div className="relative z-10 max-w-2xl animate-[fadeIn_1s_ease-out]">
              <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
                Revolutionieren Sie Ihre Kommunikation
              </div>
              
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                Ihr intelligenter
                <div className="relative inline-flex flex-col mt-2">
                  <span className="text-blue-600 relative z-10">Anrufbeantworter</span>
                  <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
                </div>
              </h1>
              
              <p className="mt-6 text-lg text-gray-600">
                Steigern Sie Ihre Erreichbarkeit und Effizienz mit unserem KI-gestützten 
                Anrufbeantworter. Perfekt für Unternehmen, die keinen Anruf mehr verpassen möchten.
              </p>

              <div className="flex flex-col sm:flex-row gap-4 mt-8">
                <Link
                  to="/demo"
                  className="inline-flex items-center justify-center px-6 py-3 text-lg font-semibold text-white transition-all bg-blue-600 rounded-lg hover:bg-blue-700 hover:scale-105 shadow-lg shadow-blue-500/20"
                >
                  Demo anfordern
                </Link>
                
                <Link
                  to="/pricing"
                  className="inline-flex items-center justify-center px-6 py-3 text-lg font-semibold transition-all text-blue-600 bg-white border-2 border-blue-100 rounded-lg hover:bg-blue-50 hover:border-blue-200"
                >
                  Preise ansehen
                </Link>
              </div>

              <p className="mt-4 text-sm text-gray-500">
                14 Tage kostenlos testen • Keine Kreditkarte erforderlich
              </p>
            </div>

            {/* Right Column - Benefits Grid */}
            <div className="relative z-10 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:mt-0">
              {benefits.map((benefit, index) => (
                <div 
                  key={index}
                  className="group p-6 bg-white/80 backdrop-blur rounded-xl border border-gray-100 hover:shadow-xl hover:scale-105 transition-all duration-300"
                >
                  <div className="relative">
                    <div className="p-2 w-12 h-12 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
                      <benefit.icon className="w-8 h-8 text-blue-600" />
                    </div>
                    <div className="absolute -inset-1 bg-blue-50 rounded-lg blur opacity-0 group-hover:opacity-40 transition-opacity" />
                  </div>
                  <h3 className="mt-4 text-lg font-semibold text-gray-900">
                    {benefit.title}
                  </h3>
                  <p className="mt-2 text-sm text-gray-600">
                    {benefit.description}
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* Social Proof Section */}
          <div className="relative z-10 mt-16 border-t border-gray-100">
            <div className="py-8">
              <p className="text-sm font-medium text-center text-gray-500">
                Bereits im Einsatz bei führenden Unternehmen
              </p>
              <div className="flex justify-center gap-x-12 mt-6">
                <div className="h-12 w-24 bg-gray-100 rounded animate-pulse" />
                <div className="h-12 w-24 bg-gray-100 rounded animate-pulse" />
                <div className="h-12 w-24 bg-gray-100 rounded animate-pulse" />
              </div>
            </div>
          </div>
        </div>

        {/* Dashboard Preview Section */}
        <div className="px-4 pb-24 sm:px-6 lg:px-8">
          <div className="relative">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Alles im Überblick
              </h2>
              <p className="mt-4 text-lg text-gray-600">
                Behalten Sie die Kontrolle über Ihre Kommunikation
              </p>
            </div>

            <div className="relative mx-auto max-w-5xl">
              {/* Decorative glow effects */}
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-2xl blur-2xl opacity-40" />
              
              {/* Dashboard image container */}
              <div className="relative rounded-2xl overflow-hidden shadow-2xl border border-gray-100">
                <img
                  src="https://img.ashub.de/blub-ai/img-test.png"
                  alt="Dashboard Preview"
                  className="w-full h-auto"
                />
              </div>

              {/* Floating feature cards */}
              <div className="absolute top-4 right-8 px-6 py-4 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 animate-[fadeIn_1s_ease-out]">
  <p className="text-sm font-semibold text-gray-900">Detaillierte Statistiken</p>
  <p className="text-xs text-gray-600">Volle Transparenz über alle Gespräche</p>
</div>

<div className="absolute top-1/3 -left-4 px-6 py-4 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 animate-[fadeIn_1.2s_ease-out]">
  <p className="text-sm font-semibold text-gray-900">Tagesaktuelle Auswertung</p>
  <p className="text-xs text-gray-600">Optimale Planung Ihrer Ressourcen</p>
</div>

<div className="absolute bottom-12 right-8 px-6 py-4 bg-white/90 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 animate-[fadeIn_1.4s_ease-out]">
  <p className="text-sm font-semibold text-gray-900">Aktivitätsübersicht</p>
  <p className="text-xs text-gray-600">Erkennen Sie Muster und Stoßzeiten</p>
</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;