import React, { useState, useEffect } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, Tooltip } from '@mui/material';
import axiosInstance from '../axiosConfig';

const ConversationTokenUsage = () => {
  const [tokenUsage, setTokenUsage] = useState([]);

  useEffect(() => {
    fetchTokenUsage();
  }, []);

  const fetchTokenUsage = async () => {
    try {
      const response = await axiosInstance.get('/admin/token_usage');
      setTokenUsage(response.data);
    } catch (error) {
      console.error('Error fetching token usage:', error);
    }
  };

  const calculatePrice = (tokens, rate) => (tokens / 1000000) * rate;

  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', mx: 'auto' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        Token Usage (Prices in USD)
      </Typography>

      <Paper
        elevation={0}
        sx={{
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
          overflow: 'hidden',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {['Kunde', 'Input Tokens', 'Output Tokens', 'Total Tokens', 'Durchschnittliche Tokens pro Gespräch'].map((header) => (
                <TableCell
                  key={header}
                  sx={{
                    backgroundColor: 'rgb(249 250 251)',
                    color: 'rgb(17 24 39)',
                    fontWeight: '600',
                    fontSize: '0.875rem',
                    padding: '16px',
                    borderBottom: '2px solid rgb(229 231 235)',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tokenUsage.map((usage) => {
              const totalInputTokens = Number(usage.total_input_tokens) || 0;
              const totalOutputTokens = Number(usage.total_output_tokens) || 0;
              const totalTokens = Number(usage.total_tokens) || 0;
              const totalConversations = Number(usage.total_conversations) || 1;

              const inputPrice = usage.inputPrice;
              const outputPrice = usage.outputPrice;
              const averageInputTokens = (totalInputTokens / totalConversations).toFixed(2);
              const averageOutputTokens = (totalOutputTokens / totalConversations).toFixed(2);
              const averageInputPrice = (calculatePrice(totalInputTokens, 0.15) / totalConversations).toFixed(2);
              const averageOutputPrice = (calculatePrice(totalOutputTokens, 0.6) / totalConversations).toFixed(2);

              const cellStyles = {
                padding: '16px',
                fontSize: '0.875rem',
                color: 'rgb(55 65 81)',
                borderBottom: '1px solid rgb(229 231 235)',
              };

              const priceStyles = {
                fontSize: '0.75rem',
                color: 'rgb(107 114 128)',
                mt: 1,
                pt: 1,
                borderTop: '1px solid rgb(229 231 235)',
              };

              return (
                <TableRow
                  key={usage.customer_id}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgb(249 250 251)',
                    },
                    transition: 'background-color 0.2s',
                  }}
                >
                  <TableCell sx={{ ...cellStyles, fontWeight: '500' }}>
                    {usage.customer_name}
                  </TableCell>
                  <TableCell sx={cellStyles}>
                    <Tooltip 
                      title={`Input Price: $${inputPrice}`} 
                      placement="top" 
                      arrow
                      sx={{
                        backgroundColor: 'white',
                        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                        borderRadius: '0.5rem',
                      }}
                    >
                      <Box>
                        <Typography>{totalInputTokens}</Typography>
                        <Typography sx={priceStyles}>${inputPrice}</Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={cellStyles}>
                    <Tooltip 
                      title={`Output Price: $${outputPrice}`} 
                      placement="top" 
                      arrow
                    >
                      <Box>
                        <Typography>{totalOutputTokens}</Typography>
                        <Typography sx={priceStyles}>${outputPrice}</Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={cellStyles}>
                    <Tooltip 
                      title={`Input Price: $${inputPrice} | Output Price: $${outputPrice}`} 
                      placement="top" 
                      arrow
                    >
                      <Box>
                        <Typography>{totalTokens}</Typography>
                        <Typography sx={priceStyles}>
                          ${inputPrice} + ${outputPrice}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={cellStyles}>
                    <Tooltip
                      title={`Average Input Price: $${averageInputPrice} | Average Output Price: $${averageOutputPrice}`}
                      placement="top"
                      arrow
                    >
                      <Box>
                        <Typography>
                          {averageInputTokens} (Input) + {averageOutputTokens} (Output)
                        </Typography>
                        <Typography sx={priceStyles}>
                          ${averageInputPrice} + ${averageOutputPrice}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};

export default ConversationTokenUsage;
