import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Home, Chat, BarChart, Settings, SupportAgent, Menu as MenuIcon, Close as CloseIcon, AdminPanelSettings, RecordVoiceOver } from '@mui/icons-material';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import axiosInstance from '../axiosConfig'; // Make sure to adjust the import path

const SidebarContainer = styled.div`
  width: 250px;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7); /* Lighter glassmorphism effect */
  backdrop-filter: blur(15px); /* Backdrop blur */
  color: #333; /* Darker text color for contrast */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  justify-content: space-between;

  @media (max-width: 768px) {
    position: absolute;
    left: ${props => (props.isOpen ? '0' : '-250px')};
    transition: left 0.3s;
  }
`;

const Logo = styled.div`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #007bff; /* Blue color for logo */
`;

const Menu = styled.div`
  width: 100%;
  flex: 1;
`;

const MenuItem = styled.div`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  text-align: left;
  cursor: pointer;
  border-radius: 8px;
  transition: background 0.3s, transform 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    background: rgba(0, 123, 255, 0.1); /* Blue hover effect */
    transform: scale(1.05); /* Slight scale on hover */
  }

  & > svg {
    margin-right: 10px;
    color: #007bff; /* Blue icon color */
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  display: none;
  z-index: 1000;

  @media (max-width: 768px) {
    display: block;
  }

  & > svg {
    color: #007bff; /* Blue color for toggle button */
  }
`;

const Greeting = styled.div`
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333; /* Darker text color */
  min-height: 1.5em; /* Ensure consistent height to prevent layout shift */
`;

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const FooterLink = styled.a`
  color: #007bff; /* Blue color for footer links */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3; /* Darker blue on hover */
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const SocialIcon = styled.a`
  color: #007bff; /* Blue color for social icons */
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #0056b3; /* Darker blue on hover */
  }
`;

const FooterText = styled.p`
  color: #333; /* Darker text color */
  font-size: 0.875rem;
  text-align: center;
`;

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [role, setRole] = useState('');
  const [greeting, setGreeting] = useState('Laden...'); // Default loading message

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axiosInstance.get('/user_info');
        const data = response.data;
        if (data.displayname) {
          setDisplayName(data.displayname);
          setRole(data.role);
          setGreeting(getGreetingMessage(data.displayname));
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  const getGreetingMessage = (name) => {
    const hour = new Date().getHours();
    if (hour < 5) {
      return `Oh, auch noch wach ${name}?`;
    } else if (hour < 12) {
      return `Guten Morgen, ${name}`;
    } else if (hour < 18) {
      return `Hallo, ${name}`;
    } else {
      return `Guten Abend, ${name}`;
    }
  };

  return (
    <>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </ToggleButton>
      <SidebarContainer isOpen={isOpen}>
        <div>
          <Greeting>{greeting}</Greeting>
          <Menu>
            <MenuItem onClick={() => navigate('/dashboard')}>
              <Home />
              Dashboard
            </MenuItem>
            <MenuItem onClick={() => navigate('/conversations')}>
              <RecordVoiceOver />
              Konversationen
            </MenuItem>
            <MenuItem onClick={() => navigate('/chats')}>
              <Chat />
              Chats
            </MenuItem>
            <MenuItem onClick={() => navigate('/statistics')}>
              <BarChart />
              Statistiken
            </MenuItem>
            <MenuItem onClick={() => navigate('/settings')}>
              <Settings />
              Einstellungen
            </MenuItem>
            <MenuItem onClick={() => navigate('/agents')}>
              <SupportAgent />
              Agenten
            </MenuItem>
            {role === 'Global Admin' && (
              <MenuItem onClick={() => navigate('/admin')}>
                <AdminPanelSettings />
                Admin Panel
              </MenuItem>
            )}
          </Menu>
        </div>
        <div>
          <FooterLinks>
            <FooterLink href="/impressum">Impressum</FooterLink>
            <FooterLink href="/datenschutz">Datenschutz</FooterLink>
            <FooterLink href="/agb">AGB</FooterLink>
          </FooterLinks>
          <SocialIcons>
            <SocialIcon href="https://www.facebook.com" aria-label="Facebook">
              <FaFacebook />
            </SocialIcon>
            <SocialIcon href="https://www.twitter.com" aria-label="Twitter">
              <FaTwitter />
            </SocialIcon>
            <SocialIcon href="https://www.linkedin.com" aria-label="LinkedIn">
              <FaLinkedin />
            </SocialIcon>
          </SocialIcons>
          <FooterText>&copy; {new Date().getFullYear()} blub.ai. All rights reserved.</FooterText>
        </div>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
