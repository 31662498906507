import React from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import { Visibility, Done, Phone, MoreVert } from '@mui/icons-material';

const ActionButtons = ({ conversation, handleOpenDialog }) => {
  const buttonStyles = {
    padding: '8px',
    '&:hover': {
      backgroundColor: 'rgb(243 244 246)', // bg-gray-100
      transform: 'scale(1.05)',
      transition: 'all 0.2s ease-in-out',
    },
    transition: 'all 0.2s ease-in-out',
  };

  const iconStyles = {
    fontSize: '1.25rem',
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'flex-start', 
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <Tooltip 
        title="Anzeigen" 
        arrow 
        placement="top"
      >
        <IconButton
          onClick={() => (window.location.href = `/conversation/${conversation.conversation_id}`)}
          sx={{
            ...buttonStyles,
            color: 'rgb(59 130 246)', // text-blue-500
            '&:hover': {
              ...buttonStyles['&:hover'],
              backgroundColor: 'rgb(239 246 255)', // bg-blue-50
            },
          }}
        >
          <Visibility sx={iconStyles} />
        </IconButton>
      </Tooltip>

      {!conversation.is_done && (
        <Tooltip 
          title="Erledigt" 
          arrow 
          placement="top"
        >
          <IconButton
            onClick={() => handleOpenDialog(conversation)}
            sx={{
              ...buttonStyles,
              color: 'rgb(34 197 94)', // text-green-500
              '&:hover': {
                ...buttonStyles['&:hover'],
                backgroundColor: 'rgb(240 253 244)', // bg-green-50
              },
            }}
          >
            <Done sx={iconStyles} />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip 
        title="Anrufen" 
        arrow 
        placement="top"
      >
        <IconButton
          onClick={() => (window.location.href = `tel:${conversation.phone_number}`)}
          sx={{
            ...buttonStyles,
            color: 'rgb(59 130 246)', // text-blue-500
            '&:hover': {
              ...buttonStyles['&:hover'],
              backgroundColor: 'rgb(239 246 255)', // bg-blue-50
            },
          }}
        >
          <Phone sx={iconStyles} />
        </IconButton>
      </Tooltip>

      <Tooltip 
        title="Mehr" 
        arrow 
        placement="top"
      >
        <IconButton
          sx={{
            ...buttonStyles,
            color: 'rgb(107 114 128)', // text-gray-500
          }}
        >
          <MoreVert sx={iconStyles} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ActionButtons;
