import React from 'react';
import { TableCell, IconButton, Tooltip, Box } from '@mui/material';
import { Info } from '@mui/icons-material';

const AttributeCell = ({ attribute, dynamicAttributes, originalAttributes }) => {
  const value = dynamicAttributes ? dynamicAttributes[attribute.attribute_name] : '';
  const originalValue = originalAttributes && originalAttributes[attribute.attribute_name];

  return (
    <TableCell 
      sx={{ 
        textAlign: 'left', 
        padding: '16px 12px',
        backgroundColor: 'transparent',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          color: 'rgb(31 41 55)', // text-gray-800
          fontSize: '0.875rem',
          fontWeight: '500',
          letterSpacing: '0.01em',
        }}
      >
        <span>{value}</span>
        {originalValue && originalValue !== value && (
          <Tooltip 
            title={`Original: ${originalValue}`}
            arrow
            placement="top"
            sx={{
              backgroundColor: 'rgb(255 255 255)',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
              borderRadius: '0.5rem',
            }}
          >
            <IconButton 
              size="small"
              sx={{
                color: 'rgb(107 114 128)', // text-gray-500
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'rgb(243 244 246)', // bg-gray-100
                  color: 'rgb(59 130 246)', // text-blue-500
                  transform: 'scale(1.05)',
                  transition: 'all 0.2s ease-in-out',
                },
                transition: 'all 0.2s ease-in-out',
              }}
            >
              <Info 
                sx={{ 
                  fontSize: '1rem',
                }} 
              />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};

export default AttributeCell;
