import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Box, IconButton, Tooltip, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Grid, CircularProgress
} from '@mui/material';
import { ArrowBack, PlayArrow, Headset, HeadsetOff, Message as MessageIcon, Phone as PhoneIcon, AccessTime as TimeIcon } from '@mui/icons-material';
import axiosInstance from '../../axiosConfig';
import Sidebar from '../../components/Sidebar';
import Footer from '../../components/Footer';
import AudioStream from '../../components/AudioStream';
import { io } from "socket.io-client";
import { Helmet } from 'react-helmet';
import Notes from '../../components/Notes';

const ConversationDetails = () => {
  const { conversation_id } = useParams();
  const [conversation, setConversation] = useState(null);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [extensionNumber, setExtensionNumber] = useState('');
  const socketRef = useRef(null);

  useEffect(() => {
    const fetchConversationDetails = async () => {
      try {
        const response = await axiosInstance.get(`/conversation/${conversation_id}`);
        setConversation(response.data);
      } catch (error) {
        console.error("There was an error fetching the conversation details!", error);
      }
    };

    fetchConversationDetails();

    socketRef.current = io('https://blub.ai', {
      path: '/socket.io',
      transports: ['websocket'],
    });

    socketRef.current.on("connect", () => {
      console.log("Connected to WebSocket server with ID:", socketRef.current.id);
    });

    socketRef.current.on("new_message", (data) => {
      if (data.conversation_id === conversation_id) {
        setConversation((prevConversation) => ({
          ...prevConversation,
          messages: [...prevConversation.messages, {
            id: Date.now(),
            timestamp: data.timestamp,
            role: data.role,
            content: data.message,
            audio_file_url: data.audio_url
          }]
        }));
      }
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [conversation_id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [conversation]);

  const roleMapping = {
    user: "Anrufer",
    assistant: "Assistent"
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('de-DE', {
      timeZone: 'Europe/Berlin',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  };

  const handlePlayAudio = async (messageId) => {
    try {
      const response = await axiosInstance.get(`/audio/${conversation_id}/${messageId}`, { responseType: 'blob' });
      const audioUrl = URL.createObjectURL(response.data);
      const audio = new Audio(audioUrl);
      audio.play();
    } catch (error) {
      console.error("There was an error fetching the audio file!", error);
    }
  };

  const handleTakeCallClick = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleModalSubmit = async () => {
    try {
      await axiosInstance.post(`/take_call`, {
        conversation_id: conversation_id,
        extension_number: extensionNumber
      });
      setModalOpen(false);
    } catch (error) {
      console.error("There was an error taking the call!", error);
    }
  };

  const handleSubscribe = async () => {
    try {
      await axiosInstance.post(`/stream/subscribe`, {
        conversation_id,
        socket_id: socketRef.current.id
      });
      setIsSubscribed(true);
    } catch (error) {
      console.error("There was an error subscribing to the stream!", error);
    }
  };

  const handleUnsubscribe = () => setIsSubscribed(false);

  if (!conversation) {
    return (
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'rgb(249 250 251)',
        }}
      >
        <CircularProgress sx={{ color: 'rgb(59 130 246)' }} />
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'rgb(249 250 251)',
      }}
    >
      <Helmet>
        <title>blub.ai - Gesprächsdetails</title>
        <meta name="description" content="Überprüfen Sie die Details und den Verlauf Ihrer Telefonate im blub.ai-Dashboard." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.blub.ai/conversation/:conversation_id" />
      </Helmet>

      <Sidebar />

      <Box 
        sx={{ 
          flex: 1,
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <Container 
          maxWidth="xl" 
          sx={{ 
            py: 4,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              p: 4,
              borderRadius: '1rem',
              border: '1px solid rgba(229, 231, 235, 1)',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
              <Tooltip title="Zurück">
                <IconButton 
                  onClick={() => navigate(-1)}
                  sx={{
                    color: 'rgb(107 114 128)',
                    '&:hover': {
                      backgroundColor: 'rgb(243 244 246)',
                    },
                  }}
                >
                  <ArrowBack />
                </IconButton>
              </Tooltip>
              <Typography
                variant="h4"
                sx={{
                  ml: 2,
                  color: 'rgb(17 24 39)',
                  fontWeight: '600',
                }}
              >
                Konversationsdetails
              </Typography>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} md={4}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: '0.75rem',
                    border: '1px solid rgba(229, 231, 235, 1)',
                    backgroundColor: 'white',
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(17 24 39)',
                      fontWeight: '600',
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <MessageIcon sx={{ color: 'rgb(59 130 246)' }} />
                    Zusammenfassung
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgb(55 65 81)',
                      fontSize: '0.875rem',
                      lineHeight: 1.5,
                    }}
                  >
                    {conversation.summary}
                  </Typography>
                </Paper>

                <Paper
                  elevation={0}
                  sx={{
                    p: 3,
                    borderRadius: '0.75rem',
                    border: '1px solid rgba(229, 231, 235, 1)',
                    backgroundColor: 'white',
                    mb: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(17 24 39)',
                      fontWeight: '600',
                      mb: 3,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <TimeIcon sx={{ color: 'rgb(59 130 246)' }} />
                    Details
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                      <Typography sx={{ color: 'rgb(107 114 128)', fontSize: '0.75rem', mb: 0.5 }}>
                        Angerufen
                      </Typography>
                      <Typography sx={{ color: 'rgb(17 24 39)', fontSize: '0.875rem' }}>
                        {formatTimestamp(conversation.created_at)}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ color: 'rgb(107 114 128)', fontSize: '0.75rem', mb: 0.5 }}>
                        Gespräch beendet
                      </Typography>
                      <Typography sx={{ color: 'rgb(17 24 39)', fontSize: '0.875rem' }}>
                        {formatTimestamp(conversation.ended_at)}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ color: 'rgb(107 114 128)', fontSize: '0.75rem', mb: 0.5 }}>
                        Konversations-ID
                      </Typography>
                      <Typography sx={{ color: 'rgb(17 24 39)', fontSize: '0.875rem' }}>
                        {conversation.conversation_id}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={{ color: 'rgb(107 114 128)', fontSize: '0.75rem', mb: 0.5 }}>
                        Tel-Nr
                      </Typography>
                      <Typography sx={{ color: 'rgb(17 24 39)', fontSize: '0.875rem' }}>
                        {conversation.phone_number}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>

                {!conversation.ended_at && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Button
                      variant="contained"
                      onClick={handleTakeCallClick}
                      startIcon={<PhoneIcon />}
                      sx={{
                        backgroundColor: 'rgb(59 130 246)',
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: '500',
                        padding: '0.75rem',
                        borderRadius: '0.5rem',
                        '&:hover': {
                          backgroundColor: 'rgb(37 99 235)',
                        },
                      }}
                    >
                      Anruf übernehmen
                    </Button>
                    <Button
                      variant="contained"
                      onClick={isSubscribed ? handleUnsubscribe : handleSubscribe}
                      startIcon={isSubscribed ? <HeadsetOff /> : <Headset />}
                      sx={{
                        backgroundColor: isSubscribed ? 'rgb(239 68 68)' : 'rgb(59 130 246)',
                        color: 'white',
                        textTransform: 'none',
                        fontWeight: '500',
                        padding: '0.75rem',
                        borderRadius: '0.5rem',
                        '&:hover': {
                          backgroundColor: isSubscribed ? 'rgb(220 38 38)' : 'rgb(37 99 235)',
                        },
                      }}
                    >
                      {isSubscribed ? "Stream beenden" : "Stream abonnieren"}
                    </Button>
                  </Box>
                )}

                <Box sx={{ mt: 3 }}>
                  <Notes
                    conversationId={conversation_id}
                    notes={conversation.notes || []}
                    setConversation={setConversation}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={8}>
                <Paper
                  elevation={0}
                  sx={{
                    borderRadius: '0.75rem',
                    border: '1px solid rgba(229, 231, 235, 1)',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: 'rgb(17 24 39)',
                      fontWeight: '600',
                      p: 2,
                      borderBottom: '1px solid rgb(229 231 235)',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <MessageIcon sx={{ color: 'rgb(59 130 246)' }} />
                    Nachrichten
                  </Typography>

                  <TableContainer sx={{ maxHeight: '60vh' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell 
                            sx={{ 
                              backgroundColor: 'rgb(249 250 251)',
                              fontWeight: '600',
                              color: 'rgb(17 24 39)',
                            }}
                          >
                            Timestamp
                          </TableCell>
                          <TableCell 
                            sx={{ 
                              backgroundColor: 'rgb(249 250 251)',
                              fontWeight: '600',
                              color: 'rgb(17 24 39)',
                            }}
                          >
                            Rolle
                          </TableCell>
                          <TableCell 
                            sx={{ 
                              backgroundColor: 'rgb(249 250 251)',
                              fontWeight: '600',
                              color: 'rgb(17 24 39)',
                            }}
                          >
                            Nachricht
                          </TableCell>
                          <TableCell 
                            sx={{ 
                              backgroundColor: 'rgb(249 250 251)',
                              fontWeight: '600',
                              color: 'rgb(17 24 39)',
                            }}
                          >
                            Audio
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {conversation.messages.map((message) => (
                          <TableRow 
                            key={message.id}
                            sx={{
                              transition: 'background-color 0.2s',
                              backgroundColor: message.new ? 'rgb(239 246 255)' : 'transparent',
                              '&:hover': {
                                backgroundColor: 'rgb(243 244 246)',
                              },
                            }}
                          >
                            <TableCell sx={{ color: 'rgb(107 114 128)', fontSize: '0.875rem' }}>
                              {formatTimestamp(message.timestamp)}
                            </TableCell>
                            <TableCell sx={{ color: 'rgb(17 24 39)', fontSize: '0.875rem', fontWeight: '500' }}>
                              {roleMapping[message.role]}
                            </TableCell>
                            <TableCell sx={{ color: 'rgb(55 65 81)', fontSize: '0.875rem' }}>
                              {message.content}
                            </TableCell>
                            <TableCell>
                              {message.audio_file_url && (
                                <IconButton 
                                  onClick={() => handlePlayAudio(message.id)}
                                  sx={{
                                    color: 'rgb(59 130 246)',
                                    '&:hover': {
                                      backgroundColor: 'rgb(239 246 255)',
                                    },
                                  }}
                                >
                                  <PlayArrow />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow ref={messagesEndRef} />
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Footer />
      </Box>

      <Dialog 
        open={modalOpen} 
        onClose={handleModalClose}
        PaperProps={{
          sx: {
            borderRadius: '0.75rem',
            boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
          },
        }}
      >
        <DialogTitle
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '600',
            borderBottom: '1px solid rgb(229 231 235)',
            p: 3,
          }}
        >
          Anruf übernehmen
        </DialogTitle>
        <DialogContent sx={{ p: 3, pt: 3 }}>
          <TextField
            autoFocus
            margin="dense"
            label="Meine Durchwahl"
            type="tel"
            fullWidth
            value={extensionNumber}
            onChange={(e) => setExtensionNumber(e.target.value)}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '0.5rem',
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(59 130 246)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgb(59 130 246)',
                  borderWidth: '2px',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 3, borderTop: '1px solid rgb(229 231 235)' }}>
          <Button 
            onClick={handleModalClose}
            sx={{
              color: 'rgb(107 114 128)',
              textTransform: 'none',
              fontWeight: '500',
              '&:hover': {
                backgroundColor: 'rgb(243 244 246)',
              },
            }}
          >
            Abbrechen
          </Button>
          <Button 
            onClick={handleModalSubmit}
            variant="contained"
            sx={{
              backgroundColor: 'rgb(59 130 246)',
              color: 'white',
              textTransform: 'none',
              fontWeight: '500',
              borderRadius: '0.5rem',
              '&:hover': {
                backgroundColor: 'rgb(37 99 235)',
              },
            }}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>

      <AudioStream socket={socketRef.current} isSubscribed={isSubscribed} />
    </Box>
  );
};

export default ConversationDetails;
