import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid } from '@mui/material';
import axiosInstance from '../axiosConfig';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

const AdminDashboard = () => {
  const [stats, setStats] = useState({
    conversations: [],
    averageTokenUsage: [],
    totalTokenUsage: {},
    averageConversationDuration: [],
    totalConversationDuration: {},
    totalConversationsPerCustomer: {},
    customers: []
  });
  const [customerColors, setCustomerColors] = useState({});

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const response = await axiosInstance.get('/admin/global-statistics?days=90');
      const normalizedData = normalizeData(response.data);
      setStats(normalizedData);
      setCustomerColors(generateColors(normalizedData.customers));
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  const normalizeData = (data) => {
    const defaultItem = { time: '', total: 0 };
    const customers = data.customers.map(customer => customer.name);

    const normalizeArray = (array, defaultValue) => {
      return array.map(item => ({
        ...defaultValue,
        ...item,
        ...customers.reduce((acc, customer) => ({
          ...acc,
          [customer]: item[customer] || 0
        }), {})
      }));
    };

    const normalizedData = { ...data };
    normalizedData.conversations = normalizeArray(data.conversations, defaultItem);
    normalizedData.averageTokenUsage = normalizeArray(data.averageTokenUsage, defaultItem);
    normalizedData.averageConversationDuration = normalizeArray(data.averageConversationDuration, defaultItem);

    normalizedData.totalConversationDuration = Object.keys(data.totalConversationDuration).reduce((acc, key) => {
      acc[key] = secondsToHms(data.totalConversationDuration[key]);
      return acc;
    }, {});

    return normalizedData;
  };

  const generateColors = (customers) => {
    const baseColors = [
      '#3B82F6', // blue-500
      '#10B981', // emerald-500
      '#F59E0B', // amber-500
      '#EF4444', // red-500
      '#8B5CF6', // violet-500
      '#EC4899', // pink-500
      '#14B8A6', // teal-500
      '#F97316', // orange-500
    ];

    const colors = {};
    customers.forEach((customer, index) => {
      colors[customer.name] = baseColors[index % baseColors.length];
    });
    return colors;
  };

  const secondsToHms = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor(seconds % 3600 / 60);
    const s = Math.floor(seconds % 3600 % 60);

    const hDisplay = h > 0 ? h + "h " : "";
    const mDisplay = m > 0 ? m + "m " : "";
    const sDisplay = s > 0 ? s + "s" : "";
    return hDisplay + mDisplay + sDisplay || "0s";
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper
          elevation={0}
          sx={{
            p: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            border: '1px solid rgb(229 231 235)',
            borderRadius: '0.5rem',
          }}
        >
          <Typography sx={{ color: 'rgb(17 24 39)', fontWeight: '600', mb: 1 }}>
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography
              key={index}
              sx={{
                color: entry.color,
                fontSize: '0.875rem',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <Box
                component="span"
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: '50%',
                  backgroundColor: entry.color,
                }}
              />
              {entry.name}: {entry.value}
            </Typography>
          ))}
        </Paper>
      );
    }
    return null;
  };

  const renderChart = (title, dataKey, data, totalData) => (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '600',
            mb: 3,
          }}
        >
          {title}
        </Typography>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="rgb(229 231 235)" />
            <XAxis
              dataKey="time"
              stroke="rgb(107 114 128)"
              tick={{ fill: 'rgb(107 114 128)', fontSize: 12 }}
            />
            <YAxis
              stroke="rgb(107 114 128)"
              tick={{ fill: 'rgb(107 114 128)', fontSize: 12 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              wrapperStyle={{
                paddingTop: '20px',
              }}
            />
            <Line
              type="monotone"
              dataKey="total"
              stroke="rgb(59 130 246)"
              strokeWidth={2}
              dot={{ fill: 'rgb(59 130 246)', strokeWidth: 2 }}
              activeDot={{ r: 6 }}
            />
            {stats.customers.map((customer) => (
              <Line
                key={customer.id}
                type="monotone"
                dataKey={customer.name}
                stroke={customerColors[customer.name]}
                strokeWidth={2}
                dot={{ fill: customerColors[customer.name], strokeWidth: 2 }}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
        <Box 
          sx={{ 
            mt: 3,
            p: 2,
            backgroundColor: 'rgb(249 250 251)',
            borderRadius: '0.5rem',
          }}
        >
          <Typography 
            variant="subtitle1"
            sx={{
              color: 'rgb(17 24 39)',
              fontWeight: '600',
              mb: 2,
            }}
          >
            Gesamte {title.split(' ')[1]}
          </Typography>
          <Grid container spacing={2}>
            {stats.customers.map(customer => (
              <Grid item xs={12} sm={6} md={4} key={customer.id}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: customerColors[customer.name],
                    }}
                  />
                  <Typography
                    sx={{
                      color: 'rgb(55 65 81)',
                      fontSize: '0.875rem',
                    }}
                  >
                    {customer.name}: {totalData[customer.name] || 0}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );

  return (
    <Box sx={{ width: '100%', maxWidth: '1400px', mx: 'auto' }}>
      <Typography
        variant="h5"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        {renderChart('Geführte Konversationen', 'total', stats.conversations, stats.totalConversationsPerCustomer)}
        {renderChart('Durchschnittliche Token-Nutzung pro Gespräch', 'averageTokenUsage', stats.averageTokenUsage, stats.totalTokenUsage)}
        {renderChart('Durchschnittliche Gesprächsdauer pro Gespräch', 'averageConversationDuration', stats.averageConversationDuration, stats.totalConversationDuration)}
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
