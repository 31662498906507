import React, { useState } from 'react';
import { supabase } from '../../supabaseClient';
import { useAuth } from '../../AuthContext';
import { 
  Button, 
  TextField, 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Stack
} from '@mui/material';

// Password Change Dialog Component
export const ChangePasswordDialog = ({ open, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleChangePassword = async () => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccess(true);
      setError('');
      setTimeout(() => {
        onClose();
        setSuccess(false);
        setCurrentPassword('');
        setNewPassword('');
      }, 2000);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Passwort ändern</DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 2 }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">Passwort erfolgreich geändert!</Alert>}
          <TextField
            label="Aktuelles Passwort"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
          />
          <TextField
            label="Neues Passwort"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Abbrechen</Button>
        <Button onClick={handleChangePassword} variant="contained">
          Passwort ändern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Password Reset Request Component
export const PasswordResetRequest = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleResetRequest = async () => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://blub.ai/reset-password',
      });

      if (error) throw error;

      setSuccess(true);
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && (
        <Alert severity="success">
          Anweisungen zum Zurücksetzen des Passworts wurden an Ihre E-Mail-Adresse gesendet.
        </Alert>
      )}
      <TextField
        label="E-Mail-Adresse"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
      />
      <Button onClick={handleResetRequest} variant="contained" fullWidth>
        Passwort zurücksetzen
      </Button>
    </Stack>
  );
};

// Password Reset Completion Component
export const PasswordResetCompletion = () => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handlePasswordReset = async () => {
    try {
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) throw error;

      setSuccess(true);
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Stack spacing={2} sx={{ maxWidth: 400, mx: 'auto', mt: 4 }}>
      {error && <Alert severity="error">{error}</Alert>}
      {success && (
        <Alert severity="success">
          Passwort erfolgreich zurückgesetzt! Sie können sich jetzt anmelden.
        </Alert>
      )}
      <TextField
        label="Neues Passwort"
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        fullWidth
      />
      <Button onClick={handlePasswordReset} variant="contained" fullWidth>
        Neues Passwort speichern
      </Button>
    </Stack>
  );
};

export default {
  ChangePasswordDialog,
  PasswordResetRequest,
  PasswordResetCompletion
};