import React, { useState, useEffect } from 'react';
import { Box, TextField, MenuItem, FormControl, InputLabel, Select, Button, Typography, Snackbar, Alert, Tooltip, Grid, Paper } from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import axiosInstance from '../axiosConfig';

const CustomerSettings = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [maxApiKeys, setMaxApiKeys] = useState('');
  const [maxAgents, setMaxAgents] = useState('');
  const [plan, setPlan] = useState('');
  const [chatEnabled, setChatEnabled] = useState(false);
  const [maxChatApiKeys, setMaxChatApiKeys] = useState('');
  const [maxEmployees, setMaxEmployees] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      const response = await axiosInstance.get('/admin/customers');
      setCustomers(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Kunden:', error);
    }
  };

  const handleCustomerChange = async (event) => {
    const customerId = event.target.value;
    setSelectedCustomer(customerId);

    try {
      const response = await axiosInstance.get('/admin/customer/settings', {
        params: { customer_id: customerId },
      });

      const settings = response.data[0];
      if (settings) {
        setMaxApiKeys(settings.max_api_keys);
        setMaxAgents(settings.max_agents);
        setPlan(settings.plan);
        setChatEnabled(settings.chat_enabled === 1);
        setMaxChatApiKeys(settings.max_chat_api_keys);
        setMaxEmployees(settings.max_employees);
      } else {
        clearFields();
      }
    } catch (error) {
      console.error('Fehler beim Laden der Kundeneinstellungen:', error);
      clearFields();
    }
  };

  const clearFields = () => {
    setMaxApiKeys('');
    setMaxAgents('');
    setPlan('');
    setChatEnabled(false);
    setMaxChatApiKeys('');
    setMaxEmployees('');
  };

  const handleSaveSettings = async () => {
    if (!selectedCustomer) {
      setSnackbarMessage('Bitte wählen Sie einen Kunden aus.');
      setOpenSnackbar(true);
      return;
    }

    try {
      await axiosInstance.put('/admin/customer/settings', {
        customer_id: selectedCustomer,
        max_api_keys: maxApiKeys,
        max_agents: maxAgents,
        plan,
        chat_enabled: chatEnabled,
        max_chat_api_keys: maxChatApiKeys,
        max_employees: maxEmployees,
      });
      setSnackbarMessage('Einstellungen erfolgreich gespeichert.');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Fehler beim Speichern der Einstellungen:', error);
      setSnackbarMessage('Fehler beim Speichern der Einstellungen.');
      setOpenSnackbar(true);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', mx: 'auto' }}>
      <Typography
        variant="h4"
        sx={{
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          mb: 4,
          textAlign: 'center',
          position: 'relative',
          '&:after': {
            content: '""',
            position: 'absolute',
            bottom: '-8px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '60px',
            height: '4px',
            background: 'rgb(59 130 246)',
            borderRadius: '2px',
          },
        }}
      >
        Kundenverwaltung
      </Typography>
      
      <Paper
        elevation={0}
        sx={{
          p: 4,
          borderRadius: '1rem',
          border: '1px solid rgba(229, 231, 235, 1)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
        }}
      >
        <FormControl fullWidth sx={{ ...inputStyles, mb: 4 }}>
          <InputLabel>Kunde auswählen</InputLabel>
          <Select 
            value={selectedCustomer} 
            onChange={handleCustomerChange} 
            label="Kunde auswählen"
          >
            {customers.map((customer) => (
              <MenuItem 
                key={customer.id} 
                value={customer.id}
                sx={{
                  borderRadius: '0.25rem',
                  '&:hover': {
                    backgroundColor: 'rgb(243 244 246)',
                  },
                }}
              >
                {customer.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedCustomer && (
          <Box sx={{ mt: 4 }}>
            <Typography
              variant="h6"
              sx={{
                color: 'rgb(17 24 39)',
                fontWeight: '600',
                mb: 3,
              }}
            >
              Kundeneinstellungen
            </Typography>
            
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Maximale Anzahl an API-Schlüsseln, die dieser Kunde verwenden darf"
                  arrow
                  placement="top"
                >
                  <TextField
                    fullWidth
                    label="Max API-Schlüssel"
                    value={maxApiKeys}
                    onChange={(e) => setMaxApiKeys(e.target.value)}
                    type="number"
                    sx={inputStyles}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Maximale Anzahl an Agenten für diesen Kunden"
                  arrow
                  placement="top"
                >
                  <TextField
                    fullWidth
                    label="Max Agenten"
                    value={maxAgents}
                    onChange={(e) => setMaxAgents(e.target.value)}
                    type="number"
                    sx={inputStyles}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Aktueller Plan des Kunden (basic, pro, enterprise)"
                  arrow
                  placement="top"
                >
                  <FormControl fullWidth sx={inputStyles}>
                    <InputLabel>Plan</InputLabel>
                    <Select 
                      value={plan} 
                      onChange={(e) => setPlan(e.target.value)} 
                      label="Plan"
                    >
                      <MenuItem value="basic">Basic</MenuItem>
                      <MenuItem value="pro">Pro</MenuItem>
                      <MenuItem value="enterprise">Enterprise</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Aktiviert oder deaktiviert die Chat-Funktion für diesen Kunden"
                  arrow
                  placement="top"
                >
                  <FormControl fullWidth sx={inputStyles}>
                    <InputLabel>Chat aktiviert</InputLabel>
                    <Select 
                      value={chatEnabled} 
                      onChange={(e) => setChatEnabled(e.target.value === 'true')} 
                      label="Chat aktiviert"
                    >
                      <MenuItem value="true">Ja</MenuItem>
                      <MenuItem value="false">Nein</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Maximale Anzahl an API-Schlüsseln für den Chat-Service"
                  arrow
                  placement="top"
                >
                  <TextField
                    fullWidth
                    label="Max Chat API-Schlüssel"
                    value={maxChatApiKeys}
                    onChange={(e) => setMaxChatApiKeys(e.target.value)}
                    type="number"
                    sx={inputStyles}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} md={6}>
                <Tooltip 
                  title="Maximale Anzahl an Mitarbeitern"
                  arrow
                  placement="top"
                >
                  <TextField
                    fullWidth
                    label="Max Mitarbeiter"
                    value={maxEmployees}
                    onChange={(e) => setMaxEmployees(e.target.value)}
                    type="number"
                    sx={inputStyles}
                  />
                </Tooltip>
              </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button
                variant="contained"
                onClick={handleSaveSettings}
                startIcon={<SaveIcon />}
                sx={{
                  backgroundColor: 'rgb(59 130 246)',
                  color: 'white',
                  textTransform: 'none',
                  fontWeight: '500',
                  padding: '0.5rem 2rem',
                  borderRadius: '0.5rem',
                  '&:hover': {
                    backgroundColor: 'rgb(37 99 235)',
                  },
                  '&:disabled': {
                    backgroundColor: 'rgb(219 234 254)',
                    color: 'rgb(147 197 253)',
                  },
                }}
              >
                Einstellungen speichern
              </Button>
            </Box>
          </Box>
        )}
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleSnackbarClose} 
          severity="info"
          sx={{
            borderRadius: '0.5rem',
            backgroundColor: 'white',
            color: 'rgb(59 130 246)',
            '& .MuiAlert-icon': {
              color: 'rgb(59 130 246)',
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CustomerSettings;
