import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://blub.ai/api',
});

/*const axiosInstance = axios.create({
  baseURL: 'http://localhost:5000/api',
});
*/
export default axiosInstance;
