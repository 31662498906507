import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Box } from '@mui/material';
import { PasswordResetCompletion } from '../../pages/backend/PasswordResetCompletion';

const PasswordResetPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Get the token from the URL
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  // If successful password reset, redirect to login
  const handleSuccess = () => {
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Neues Passwort setzen
          </Typography>
          
          <Typography variant="body1" align="center" sx={{ mb: 4 }}>
            Bitte geben Sie Ihr neues Passwort ein.
          </Typography>

          <PasswordResetCompletion token={token} onSuccess={handleSuccess} />
        </Paper>
      </Box>
    </Container>
  );
};

export default PasswordResetPage;