import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
  Paper,
} from '@mui/material';
import { Send, Email, Sms, Cancel } from '@mui/icons-material';
import axiosInstance from '../axiosConfig';

const ConversationActions = ({ conversation, open, onClose, onMarkAsDone }) => {
  const [responseMethod, setResponseMethod] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(conversation.phone_number || '');
  const [phoneError, setPhoneError] = useState(false);
  const [previewMessage, setPreviewMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedTemplate) {
      const fetchTemplatePreview = async () => {
        try {
          const response = await axiosInstance.get(`/template-preview/${selectedTemplate}`, {
            params: { conversation_id: conversation.conversation_id },
          });
          setPreviewMessage(response.data.message);
        } catch (error) {
          console.error('Fehler beim Abrufen der Vorlagenvorschau:', error);
        }
      };

      fetchTemplatePreview();
    }
  }, [selectedTemplate, conversation.conversation_id]);

  useEffect(() => {
    if (responseMethod === 'sms') {
      const fetchTemplates = async () => {
        try {
          const response = await axiosInstance.get('/get-personal-templates', {
            params: { customer_id: conversation.customer_id },
          });
          setTemplates(response.data);
        } catch (error) {
          console.error('Fehler beim Abrufen der Vorlagen:', error);
        }
      };

      fetchTemplates();
    }
  }, [responseMethod, conversation.customer_id]);

  const handleSendSms = async () => {
    setLoading(true);
    try {
      await axiosInstance.post('/send-sms', {
        template_id: selectedTemplate,
        recipient: phoneNumber,
        conversation_id: conversation.conversation_id,
      });
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Senden der SMS:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);
    try {
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Senden der E-Mail:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNoResponse = async () => {
    setLoading(true);
    try {
      await handleMarkAsDone();
    } catch (error) {
      console.error('Fehler beim Markieren ohne Antwort:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkAsDone = async () => {
    try {
      await axiosInstance.post(`/summary/${conversation.conversation_id}/mark_done`);
      onMarkAsDone(conversation.conversation_id);
      onClose();
    } catch (error) {
      console.error('Fehler beim Markieren der Konversation als erledigt:', error);
    }
  };

  const handleActionComplete = async () => {
    if (responseMethod === 'sms' && !/^\+\d{1,3}\s?\d{1,14}(\s?\d{1,13})?$/.test(phoneNumber)) {
      setPhoneError(true);
      return;
    }
    setPhoneError(false);

    switch (responseMethod) {
      case 'sms':
        await handleSendSms();
        break;
      case 'email':
        await handleSendEmail();
        break;
      case 'no-response':
        await handleNoResponse();
        break;
      default:
        break;
    }
  };

  const inputStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      transition: 'all 0.2s',
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
        },
      },
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgb(59 130 246)',
          borderWidth: '2px',
        },
      },
    },
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="sm" 
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '1rem',
          backgroundColor: 'rgb(249 250 251)',
          boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.25)',
        },
      }}
    >
      <DialogTitle
        sx={{
          textAlign: 'center',
          color: 'rgb(17 24 39)',
          fontWeight: '600',
          fontSize: '1.25rem',
          pt: 3,
          pb: 2,
          borderBottom: '1px solid rgb(229 231 235)',
        }}
      >
        Wählen Sie eine Antwortmethode
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <FormControl fullWidth sx={{ ...inputStyles, mb: 3 }}>
          <InputLabel>Antwortmethode</InputLabel>
          <Select
            value={responseMethod}
            onChange={(e) => setResponseMethod(e.target.value)}
            label="Antwortmethode"
            startAdornment={
              responseMethod === 'sms' ? (
                <Sms sx={{ mr: 1, color: 'rgb(59 130 246)' }} />
              ) : responseMethod === 'email' ? (
                <Email sx={{ mr: 1, color: 'rgb(59 130 246)' }} />
              ) : null
            }
          >
            <MenuItem value="sms">per SMS</MenuItem>
            <MenuItem value="email">per E-Mail</MenuItem>
            <MenuItem value="no-response">Keine Antwort</MenuItem>
          </Select>
        </FormControl>

        {responseMethod === 'sms' && (
          <>
            <FormControl fullWidth sx={{ ...inputStyles, mb: 3 }}>
              <InputLabel>Vorlage auswählen</InputLabel>
              <Select
                value={selectedTemplate}
                onChange={(e) => setSelectedTemplate(e.target.value)}
                label="Vorlage auswählen"
              >
                {templates.map((template) => (
                  <MenuItem 
                    key={template.id} 
                    value={template.id}
                    sx={{
                      borderRadius: '0.25rem',
                      '&:hover': {
                        backgroundColor: 'rgb(243 244 246)',
                      },
                    }}
                  >
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Telefonnummer"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              error={phoneError}
              helperText={phoneError ? 'Das Format der Telefonnummer muss +XX XXX XXXXXX sein' : ''}
              sx={{ ...inputStyles, mb: 3 }}
            />

            {selectedTemplate && (
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  mb: 2,
                  borderRadius: '0.5rem',
                  border: '1px solid rgb(229 231 235)',
                  backgroundColor: 'white',
                }}
              >
                <Typography
                  sx={{
                    color: 'rgb(17 24 39)',
                    fontWeight: '600',
                    mb: 2,
                  }}
                >
                  Nachrichtenvorschau:
                </Typography>
                <Typography
                  sx={{
                    color: 'rgb(55 65 81)',
                    fontSize: '0.875rem',
                    lineHeight: 1.5,
                  }}
                >
                  {previewMessage}
                </Typography>
              </Paper>
            )}
          </>
        )}

        {responseMethod === 'email' && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'rgb(107 114 128)',
              p: 3,
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              border: '1px solid rgb(229 231 235)',
            }}
          >
            Die E-Mail Funktionalität ist derzeit nicht verfügbar.
          </Typography>
        )}

        {responseMethod === 'no-response' && (
          <Typography
            sx={{
              textAlign: 'center',
              color: 'rgb(107 114 128)',
              p: 3,
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              border: '1px solid rgb(229 231 235)',
            }}
          >
            Es wird keine Antwort gesendet.
          </Typography>
        )}
      </DialogContent>

      <DialogActions
        sx={{
          p: 3,
          borderTop: '1px solid rgb(229 231 235)',
          gap: 2,
        }}
      >
        <Button
          onClick={onClose}
          startIcon={<Cancel />}
          disabled={loading}
          sx={{
            color: 'rgb(107 114 128)',
            textTransform: 'none',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: 'rgb(243 244 246)',
            },
          }}
        >
          Abbrechen
        </Button>

        <Button
          variant="contained"
          onClick={handleActionComplete}
          startIcon={loading ? (
            <CircularProgress 
              size={20} 
              sx={{ 
                color: 'white',
              }} 
            />
          ) : (
            <Send />
          )}
          disabled={loading}
          sx={{
            backgroundColor: 'rgb(59 130 246)',
            color: 'white',
            textTransform: 'none',
            fontWeight: '500',
            padding: '0.5rem 2rem',
            borderRadius: '0.5rem',
            '&:hover': {
              backgroundColor: 'rgb(37 99 235)',
            },
            '&:disabled': {
              backgroundColor: 'rgb(219 234 254)',
              color: 'rgb(147 197 253)',
            },
          }}
        >
          {loading ? 'Wird gesendet...' : 'Bestätigen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConversationActions;
