import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Container,
  Link as MuiLink,
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';
import { PasswordResetRequest } from '../backend/PasswordResetCompletion';

const ForgotPasswordPage = () => {
  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Passwort zurücksetzen
          </Typography>
          
          <Typography variant="body1" align="center" sx={{ mb: 4 }}>
            Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.
          </Typography>

          <PasswordResetRequest />

          <Divider sx={{ my: 3 }} />

          <Typography variant="body2" align="center">
            Zurück zum{' '}
            <MuiLink component={Link} to="/login">
              Login
            </MuiLink>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPasswordPage;