import React from 'react';
import { Link } from 'react-router-dom';
import { Calendar, Settings, Rocket, MessageCircle } from 'lucide-react';

const StepsToStart = () => {
  const steps = [
    {
      icon: Calendar,
      title: "Anmelden",
      description: "Beginnen Sie noch heute Ihre 14-tägige kostenlose Testphase und nutzen Sie alle Vorteile des Telefonassistenten einen Monat lang völlig unverbindlich.",
      color: "text-blue-600",
      bgColor: "bg-blue-50"
    },
    {
      icon: Settings,
      title: "Einrichten",
      description: "Sofort nach dem On-Boarding können Sie selbständig durchstarten: Konfigurieren Sie in unter einer Stunde Ihren eigenen Telefonassistenten – ganz ohne technische Vorkenntnisse.",
      color: "text-blue-600",
      bgColor: "bg-blue-50"
    },
    {
      icon: Rocket,
      title: "Loslegen",
      description: "Genießen Sie die Ruhe und arbeiten Sie endlich wieder konzentriert, ohne vom ständigen Telefonklingeln gestört zu werden.",
      color: "text-blue-600",
      bgColor: "bg-blue-50"
    }
  ];

  return (
    <div className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/30">
      {/* Decorative elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute left-1/2 top-0 w-[800px] h-[800px] bg-blue-50/50 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2" />
        <div className="absolute right-0 bottom-0 w-[600px] h-[600px] bg-blue-50/30 rounded-full blur-3xl translate-x-1/2 translate-y-1/2" />
      </div>

      {/* Main content */}
      <div className="relative px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Header section */}
        <div className="max-w-3xl mx-auto text-center mb-20">
          <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Ihr Weg zu Ihrem individuellen
            <span className="block text-blue-600 mt-2">Telefonassistenten</span>
          </h2>
        </div>

        {/* Steps grid */}
        <div className="grid grid-cols-1 gap-8 md:grid-cols-3 relative">
          {steps.map((step, index) => (
            <div
              key={index}
              className="group relative"
            >
              {/* Connector line */}
              {index < steps.length - 1 && (
                <div className="hidden md:block absolute top-12 left-full w-full h-[2px] bg-gradient-to-r from-blue-100 to-transparent -translate-x-1/2" />
              )}
              
              {/* Card */}
              <div className="relative p-8 bg-white/80 backdrop-blur rounded-2xl border border-gray-100 hover:shadow-xl transition-all duration-300 hover:-translate-y-1">
                {/* Icon */}
                <div className="relative inline-flex mb-6">
                  <div className={`${step.bgColor} p-4 rounded-xl`}>
                    <step.icon className={`w-8 h-8 ${step.color}`} />
                  </div>
                  <div className="absolute -inset-1 bg-blue-100/50 rounded-xl blur opacity-0 group-hover:opacity-100 transition-opacity" />
                </div>

                {/* Content */}
                <h3 className="text-xl font-semibold text-gray-900 mb-4">
                  {step.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* CTA section */}
        <div className="mt-20 text-center">
          <Link
            to="/demo"
            className="inline-flex items-center px-8 py-4 text-lg font-semibold text-white bg-blue-600 rounded-xl shadow-lg shadow-blue-500/20 hover:bg-blue-700 hover:scale-105 transition-all duration-300"
          >
            14 tägigen Zugang anfordern
          </Link>
        </div>

        {/* Questions section */}
        <div className="mt-32 max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-6">
            Haben Sie noch Fragen?
          </h2>
          <p className="text-lg text-gray-600 mb-10">
            Möchten Sie mehr darüber erfahren, wie blub.ai Ihr Unternehmen unterstützen kann? Buchen Sie jetzt ein unverbindliches Gespräch mit einem unserer Experten und entdecken Sie, wie Sie mit blub.ai Ihre Telefonanfragen automatisieren und die Effizienz Ihres Kundenservices auf das nächste Level heben.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 text-lg font-semibold text-blue-600 bg-blue-50 rounded-xl hover:bg-blue-100 transition-colors duration-300"
          >
            <MessageCircle className="w-5 h-5 mr-2" />
            Zum Kontaktformular
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StepsToStart;