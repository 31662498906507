import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { Users, LayoutDashboard, Key, Shield, AlertCircle } from 'lucide-react';
import axiosInstance
 from '../axiosConfig';
const DashboardLandingPage = () => {
  const [limits, setLimits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [changelog, setChangelog] = useState('');

  useEffect(() => {
    const fetchLimitsAndChangelog = async () => {
      try {
        const limitsResponse = await axiosInstance.get('/customerlimits');
        setLimits(limitsResponse.data);
        const changelogResponse = await axiosInstance.get('/changelog');
        setChangelog(changelogResponse.data);
      } catch (error) {
        console.error('Fehler beim Abrufen der Daten:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchLimitsAndChangelog();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-blue-50/50 to-white">
        <CircularProgress className="text-blue-600" />
      </div>
    );
  }

  const calculateUsagePercentage = (current, max) => {
    return max > 0 ? ((current / max) * 100).toFixed(0) : 0;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50/50 to-white">
      <div className="relative px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Decorative elements */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -right-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
          <div className="absolute -left-1/4 top-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
        </div>

        {/* Content */}
        <div className="relative space-y-8">
          {/* Limits and Features Section */}
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl border border-gray-100">
            <h2 className="text-xl font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-100">
              Aktuelle Limits und Features
            </h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-5">
              <div className="p-4 bg-blue-50/50 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Users className="w-5 h-5 text-blue-600" />
                  <h3 className="text-sm font-medium text-gray-600">Maximale Agenten</h3>
                </div>
                <p className="text-2xl font-semibold text-gray-900">{limits?.limits?.max_agents}</p>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <LayoutDashboard className="w-5 h-5 text-blue-600" />
                  <h3 className="text-sm font-medium text-gray-600">Maximale Mitarbeiter</h3>
                </div>
                <p className="text-2xl font-semibold text-gray-900">{limits?.limits?.max_employees}</p>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Key className="w-5 h-5 text-blue-600" />
                  <h3 className="text-sm font-medium text-gray-600">Maximale Chat-API-Schlüssel</h3>
                </div>
                <p className="text-2xl font-semibold text-gray-900">{limits?.limits?.max_chat_api_keys}</p>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <Shield className="w-5 h-5 text-blue-600" />
                  <h3 className="text-sm font-medium text-gray-600">Plan</h3>
                </div>
                <p className="text-2xl font-semibold text-gray-900">
                  {limits?.limits?.plan?.charAt(0).toUpperCase() + limits?.limits?.plan?.slice(1)}
                </p>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <div className="flex items-center gap-3 mb-2">
                  <AlertCircle className="w-5 h-5 text-blue-600" />
                  <h3 className="text-sm font-medium text-gray-600">Chat aktiviert</h3>
                </div>
                <p className="text-2xl font-semibold text-gray-900">{limits?.limits?.chat_enabled ? 'Ja' : 'Nein'}</p>
              </div>
            </div>
          </div>

          {/* Usage Statistics Section */}
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl border border-gray-100">
            <h2 className="text-xl font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-100">
              Aktuelle Nutzungsstatistiken
            </h2>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div className="p-4 bg-blue-50/50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-600 mb-4">Agentennutzung</h3>
                <div className="space-y-2">
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-600 rounded-full transition-all duration-500"
                      style={{ width: `${calculateUsagePercentage(limits?.current?.current_agents, limits?.limits?.max_agents)}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-sm text-gray-600">
                    <span>{limits?.current?.current_agents} von {limits?.limits?.max_agents}</span>
                    <span>{calculateUsagePercentage(limits?.current?.current_agents, limits?.limits?.max_agents)}% genutzt</span>
                  </div>
                </div>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-600 mb-4">Mitarbeiter Lizenz Nutzung</h3>
                <div className="space-y-2">
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-600 rounded-full transition-all duration-500"
                      style={{ width: `${calculateUsagePercentage(limits?.current?.current_employees, limits?.limits?.max_employees)}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-sm text-gray-600">
                    <span>{limits?.current?.current_employees} von {limits?.limits?.max_employees}</span>
                    <span>{calculateUsagePercentage(limits?.current?.current_employees, limits?.limits?.max_employees)}% genutzt</span>
                  </div>
                </div>
              </div>

              <div className="p-4 bg-blue-50/50 rounded-lg">
                <h3 className="text-sm font-medium text-gray-600 mb-4">Chat-API-Schlüssel Nutzung</h3>
                <div className="space-y-2">
                  <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-600 rounded-full transition-all duration-500"
                      style={{ width: `${calculateUsagePercentage(limits?.current?.current_chat_api_keys, limits?.limits?.max_chat_api_keys)}%` }}
                    />
                  </div>
                  <div className="flex justify-between text-sm text-gray-600">
                    <span>{limits?.current?.current_chat_api_keys} von {limits?.limits?.max_chat_api_keys}</span>
                    <span>{calculateUsagePercentage(limits?.current?.current_chat_api_keys, limits?.limits?.max_chat_api_keys)}% genutzt</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Changelog Section */}
          <div className="p-6 bg-white/80 backdrop-blur-sm rounded-xl border border-gray-100">
            <h2 className="text-xl font-semibold text-gray-900 mb-6 pb-4 border-b border-gray-100">
              Changelog / News
            </h2>
            <div className="prose prose-blue max-w-none">
              <ReactMarkdown>
                {changelog || 'Keine Neuigkeiten verfügbar.'}
              </ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLandingPage;