import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, Tabs, Tab, CircularProgress } from '@mui/material';
import Sidebar from '../../components/Sidebar';
import BenutzerVerwaltung from '../../components/BenutzerVerwaltung';
import ConversationTokenUsage from '../../components/ConversationTokenUsage';
import AdminDashboard from '../../components/AdminDashboard';
import CustomerManagement from '../../components/CustomerManager';
import TemplateManagement from '../../components/TemplateManagement';
import ApiKeyManagement from '../../components/ApiKeyManagement';
import CustomerSettings from '../../components/CustomerSettings';
import { useAuth } from '../../AuthContext';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../axiosConfig';

const AdminSettingsPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const checkRole = async () => {
      try {
        const response = await axiosInstance.get('/user_info');
        const data = response.data;
        if (data.role === 'Global Admin') {
          setIsAuthorized(true);
        }
      } catch (error) {
        console.error('Error checking user role:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      checkRole();
    }
  }, [user]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100vh',
          backgroundColor: 'rgb(249 250 251)',
        }}
      >
        <CircularProgress sx={{ color: 'rgb(59 130 246)' }} />
      </Box>
    );
  }

  if (!isAuthorized) {
    return (
      <Box 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'rgb(249 250 251)',
          gap: 2,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: 'rgb(17 24 39)',
            fontWeight: '700',
            textAlign: 'center',
          }}
        >
          Zugriff verweigert
        </Typography>
        <Typography
          sx={{
            color: 'rgb(107 114 128)',
            textAlign: 'center',
          }}
        >
          Sie haben keine Berechtigung, auf diese Seite zuzugreifen.
        </Typography>
      </Box>
    );
  }

  return (
    <Box 
      sx={{ 
        display: 'flex',
        minHeight: '100vh',
        backgroundColor: 'rgb(249 250 251)',
      }}
    >
      <Helmet>
        <title>blub.ai - Admin-Einstellungen</title>
        <meta name="description" content="Verwalten Sie Ihre Admin-Einstellungen und Benutzerrechte im blub.ai-Dashboard." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.blub.ai/admin" />
      </Helmet>

      <Sidebar />
      
      <Box 
        sx={{ 
          flex: 1,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Background decorative elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '-10%',
            right: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.1) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
            zIndex: 0,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '-10%',
            left: '-5%',
            width: '40%',
            height: '40%',
            background: 'radial-gradient(circle, rgba(59, 130, 246, 0.05) 0%, rgba(255,255,255,0) 70%)',
            borderRadius: '50%',
            filter: 'blur(40px)',
            zIndex: 0,
          }}
        />

        <Container 
          maxWidth="xl" 
          sx={{ 
            py: 4,
            px: { xs: 2, sm: 4 },
            position: 'relative',
            zIndex: 1,
          }}
        >
          {/* Header */}
          <Typography
            variant="h4"
            component="h1"
            sx={{
              textAlign: 'center',
              marginBottom: 4,
              fontWeight: '700',
              color: 'rgb(17 24 39)',
              position: 'relative',
            }}
          >
            Admin Panel
            <Box
              sx={{
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '60px',
                height: '4px',
                background: 'rgb(59 130 246)',
                borderRadius: '2px',
              }}
            />
          </Typography>

          {/* Main Content */}
          <Paper
            elevation={0}
            sx={{
              borderRadius: '1rem',
              border: '1px solid rgba(229, 231, 235, 1)',
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.05)',
              overflow: 'hidden',
            }}
          >
            {/* Tabs */}
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                borderBottom: '1px solid rgb(229 231 235)',
                '& .MuiTabs-indicator': {
                  backgroundColor: 'rgb(59 130 246)',
                  height: '3px',
                  borderRadius: '1.5px',
                },
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  color: 'rgb(107 114 128)',
                  minHeight: '48px',
                  transition: 'all 0.2s',
                  '&:hover': {
                    color: 'rgb(17 24 39)',
                    backgroundColor: 'rgb(243 244 246)',
                  },
                  '&.Mui-selected': {
                    color: 'rgb(59 130 246)',
                    fontWeight: '600',
                  },
                },
              }}
            >
              <Tab label="Kundenverwaltung" />
              <Tab label="Kundeneinstellungen" />
              <Tab label="Benutzerverwaltung" />
              <Tab label="Token Usage" />
              <Tab label="Dashboard" />
              <Tab label="Template Management" />
              <Tab label="API Key Management" />
            </Tabs>

            {/* Tab Content */}
            <Box 
              sx={{ 
                p: { xs: 2, sm: 3, md: 4 },
                minHeight: '400px',
              }}
            >
              {activeTab === 0 && <CustomerManagement />}
              {activeTab === 1 && <CustomerSettings />}
              {activeTab === 2 && <BenutzerVerwaltung />}
              {activeTab === 3 && <ConversationTokenUsage />}
              {activeTab === 4 && <AdminDashboard />}
              {activeTab === 5 && <TemplateManagement />}
              {activeTab === 6 && <ApiKeyManagement />}
            </Box>
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminSettingsPage;
