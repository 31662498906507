import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, User, Mail, Phone, MessageSquare, Loader2, CheckCircle2, XCircle, X } from 'lucide-react';
import { Helmet } from 'react-helmet';
import axiosInstance from '../../axiosConfig';

// Custom Alert Component
const CustomAlert = ({ type, title, message, onClose }) => {
  const isError = type === 'error';
  
  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className={`relative rounded-lg p-4 ${
        isError ? 'bg-red-50 text-red-900' : 'bg-green-50 text-green-900'
      }`}
    >
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {isError ? (
            <XCircle className={`h-5 w-5 ${isError ? 'text-red-400' : 'text-green-400'}`} />
          ) : (
            <CheckCircle2 className={`h-5 w-5 ${isError ? 'text-red-400' : 'text-green-400'}`} />
          )}
        </div>
        <div className="ml-3 w-full">
          <h3 className={`text-sm font-medium ${
            isError ? 'text-red-800' : 'text-green-800'
          }`}>
            {title}
          </h3>
          <div className={`mt-2 text-sm ${
            isError ? 'text-red-700' : 'text-green-700'
          }`}>
            {message}
          </div>
        </div>
        <button
          onClick={onClose}
          className={`ml-auto flex-shrink-0 -mx-1.5 -my-1.5 p-1.5 rounded-lg inline-flex ${
            isError 
              ? 'text-red-500 hover:bg-red-100 focus:ring-red-400' 
              : 'text-green-500 hover:bg-green-100 focus:ring-green-400'
          } focus:outline-none focus:ring-2 focus:ring-offset-2`}
        >
          <X className="h-5 w-5" />
        </button>
      </div>
    </motion.div>
  );
};

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post('/send-mail', {
        subject: `Kontaktanfrage von ${formData.name}`,
        message: `Name: ${formData.name}\nE-Mail: ${formData.email}\nTelefon: ${formData.phone}\n\nNachricht:\n${formData.message}`,
      });

      if (response.status === 200) {
        setAlert({
          type: 'success',
          title: 'Nachricht gesendet!',
          message: 'Vielen Dank für Ihre Nachricht. Wir werden uns zeitnah bei Ihnen melden.'
        });
        setFormData({ name: '', email: '', phone: '', message: '' });
        setFormDisabled(true);
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setAlert({
        type: 'error',
        title: 'Fehler beim Senden',
        message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-white to-blue-50/50 py-24">
      <Helmet>
        <title>blub.ai - Kontakt</title>
        <meta name="description" content="Kontaktieren Sie uns für weitere Informationen oder Support. Das blub.ai-Team ist für Sie da." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.blub.ai/contact" />
      </Helmet>

      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <motion.div 
          className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl"
          initial={{ scale: 0.9 }}
          animate={{ scale: [0.9, 1.1, 0.9] }}
          transition={{ duration: 8, repeat: Infinity, ease: "easeInOut" }}
        />
        <motion.div 
          className="absolute -right-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl"
          initial={{ scale: 1.1 }}
          animate={{ scale: [1.1, 0.9, 1.1] }}
          transition={{ duration: 8, repeat: Infinity, ease: "easeInOut", delay: 4 }}
        />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Kontakt
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Haben Sie
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">Fragen?</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Wir freuen uns darauf, von Ihnen zu hören. Unser Team steht Ihnen bei allen Fragen zur Verfügung.
          </p>
        </div>

        {/* Alert */}
        <AnimatePresence>
          {alert && (
            <div className="max-w-xl mx-auto mb-8">
              <CustomAlert
                type={alert.type}
                title={alert.title}
                message={alert.message}
                onClose={() => setAlert(null)}
              />
            </div>
          )}
        </AnimatePresence>

        <div className="max-w-xl mx-auto">
          <motion.form 
            onSubmit={handleSubmit}
            className="space-y-6 bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-gray-100"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="space-y-4">
              {/* Name Input */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  disabled={formDisabled || loading}
                  className="block w-full pl-10 rounded-lg border border-gray-200 py-3 text-sm outline-none placeholder:text-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  placeholder="Ihr Name"
                  required
                />
              </div>

              {/* Email Input */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={formDisabled || loading}
                  className="block w-full pl-10 rounded-lg border border-gray-200 py-3 text-sm outline-none placeholder:text-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  placeholder="Ihre Email-Adresse"
                  required
                />
              </div>

              {/* Phone Input */}
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Phone className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  disabled={formDisabled || loading}
                  className="block w-full pl-10 rounded-lg border border-gray-200 py-3 text-sm outline-none placeholder:text-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  placeholder="Ihre Telefonnummer"
                  required
                />
              </div>

              {/* Message Input */}
              <div className="relative">
                <div className="absolute top-3 left-3 pointer-events-none">
                  <MessageSquare className="h-5 w-5 text-gray-400" />
                </div>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  disabled={formDisabled || loading}
                  rows="4"
                  className="block w-full pl-10 rounded-lg border border-gray-200 py-3 text-sm outline-none placeholder:text-gray-500 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
                  placeholder="Ihre Nachricht"
                  required
                />
              </div>
            </div>

            {/* Submit Button */}
            <motion.button
              type="submit"
              disabled={loading || formDisabled}
              className="group relative w-full inline-flex items-center justify-center py-3 px-4 rounded-lg text-white bg-blue-600 font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              whileHover={{ scale: 1.005 }}
              whileTap={{ scale: 0.995 }}
            >
              {loading ? (
                <Loader2 className="w-5 h-5 mr-2 animate-spin" />
              ) : (
                <Send className="w-5 h-5 mr-2 transition-transform group-hover:translate-x-1" />
              )}
              {loading ? 'Wird gesendet...' : 'Nachricht senden'}
            </motion.button>

            <p className="mt-3 text-sm text-center text-gray-500">
              Wir melden uns innerhalb von 24 Stunden bei Ihnen
            </p>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;