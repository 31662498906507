import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle, ArrowRight, Quote } from 'lucide-react';

const benefits = [
  { 
    title: 'Effizienzsteigerung', 
    description: 'Steigern Sie die Effizienz im Kundenservice durch den Einsatz modernster KI-Technologie.',
    metric: 'Bis zu 40% weniger Aufwand'
  },
  { 
    title: 'Mitarbeiterentlastung', 
    description: 'Entlasten Sie Ihre Mitarbeiter durch automatisierte Anrufverwaltung und -weiterleitung.',
    metric: '85% weniger Routineaufgaben'
  },
  { 
    title: 'Erhöhte Erreichbarkeit', 
    description: 'Verbessern Sie die Kundenzufriedenheit durch permanente Erreichbarkeit ohne Wartezeiten.',
    metric: '99.9% Verfügbarkeit'
  },
  { 
    title: '24/7 Verfügbarkeit', 
    description: 'Garantieren Sie ständige Erreichbarkeit mit unserem KI-Assistenten - rund um die Uhr.',
    metric: '0 verpasste Anrufe'
  },
];

const testimonials = [
  {
    quote: "Seit wir den KI-Anrufbeantworter von Blub.ai nutzen, hat sich unser Kundenservice spürbar verbessert. Anfragen werden schneller bearbeitet, und wir haben deutlich weniger manuelle Nacharbeit.",
    author: "Sophie Müller",
    position: "Leiterin Kundenservice"
  }
];

const Benefits = () => {
  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-blue-50/50 to-white py-24">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -right-1/4 top-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
        <div className="absolute -left-1/4 bottom-1/4 w-1/2 h-1/2 bg-blue-50/30 rounded-full blur-3xl" />
      </div>

      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <div className="inline-block px-4 py-1 mb-6 text-sm font-medium text-blue-600 bg-blue-50 rounded-full">
            Ihre Vorteile
          </div>
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
            Maximale Effizienz für
            <div className="relative inline-flex flex-col mt-2">
              <span className="text-blue-600 relative z-10 ml-3">Ihr Unternehmen</span>
              <div className="absolute bottom-0 w-full h-3 bg-blue-100/40 -skew-x-6" />
            </div>
          </h2>
          <p className="mt-4 text-lg text-gray-600 max-w-2xl mx-auto">
            Entdecken Sie, wie unsere KI-Lösung Ihre Kommunikation revolutioniert
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8 mt-16 sm:grid-cols-2 lg:grid-cols-4">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              className="group relative bg-white/80 backdrop-blur rounded-2xl p-8 border border-gray-100 hover:shadow-xl transition-all duration-300 hover:scale-105"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
            >
              <div className="relative">
                <div className="p-3 w-14 h-14 bg-blue-50 rounded-lg group-hover:bg-blue-100 transition-colors">
                  <CheckCircle className="w-8 h-8 text-blue-600" />
                </div>
                <div className="absolute -inset-1 bg-blue-50 rounded-lg blur opacity-0 group-hover:opacity-40 transition-opacity" />
              </div>
              
              <h3 className="mt-6 text-xl font-semibold text-gray-900">
                {benefit.title}
              </h3>
              
              <p className="mt-2 text-gray-600">
                {benefit.description}
              </p>

              <div className="mt-6 flex items-center text-sm font-medium text-blue-600">
                <span className="bg-blue-50 px-3 py-1 rounded-full">
                  {benefit.metric}
                </span>
                <ArrowRight className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all" />
              </div>
            </motion.div>
          ))}
        </div>

        {/* Testimonials Section */}
        <motion.div 
          className="mt-24 max-w-3xl mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="relative bg-white/80 backdrop-blur rounded-2xl p-8 border border-gray-100 shadow-lg"
            >
              <Quote className="absolute top-6 left-6 w-8 h-8 text-blue-100" />
              <div className="relative z-10">
                <p className="text-lg text-gray-700 italic">
                  "{testimonial.quote}"
                </p>
                <div className="mt-4">
                  <p className="font-semibold text-gray-900">{testimonial.author}</p>
                  <p className="text-sm text-gray-600">{testimonial.position}</p>
                </div>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default Benefits;